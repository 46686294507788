import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { InnerNavbarComponent } from './inner-navbar/inner-navbar.component';
import { BsModule } from '../../modules/bs.module';
import { SharedByAppModule } from '../../shared-module';
//import { LoginService } from '../../../account/login';


@NgModule({
    declarations: [
        NavbarComponent,
        InnerNavbarComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        BsModule,
        SharedByAppModule
    ],
    exports: [
        NavbarComponent,
        InnerNavbarComponent,
        FooterComponent
    ],
    providers: [],

})
export class CommonComponentModule {
    constructor() {
        // console.log('common called !');
    }
}