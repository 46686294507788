import { Component, ViewChild, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from 'src/app/services/account/account.service';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navrbar.service';
import { PhoneService } from './phone.service';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { LoginService } from '../login/login.service';
import { MessageService } from 'src/app/services/message.service';
import { SignupService } from "../signup";
import { PhoneNumberSignupComponent } from "src/app/shared-by-app/components/phone-number-signup/phone-number-signup.component";
import { OtpService } from "../otp";
declare var $: any;

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.css', './phone.less'],
  encapsulation: ViewEncapsulation.None
})
export class PhoneComponent implements OnInit, OnDestroy {
     @ViewChild(PhoneNumberSignupComponent, { static: false }) phoneNumberComp: PhoneNumberSignupComponent;
  @Input() id: string;
  Form: FormGroup;
  submitted = false;
  errorlog = false;
  errormsg: string = '';
  private element: any;
  public isPhoneNumberInvalid: boolean;
  obj: {};

  object: any;
  isVisibleSignUpPopUp: boolean;
  isClickBackGround: boolean;
  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private navbarSvc: NavbarService,
      private modalService: PhoneService,
      private el: ElementRef,
      public commonService: CommonService,
      private spinner: NgxSpinnerService,
      private loginService: LoginService,
      private accountSvc: AccountService,
      public signupService:SignupService,
      public otpService:OtpService,
      public messageService: MessageService,

      // private modal: NgbModal
  ) {
      this.navbarSvc.showSimpleNavbar();
      this.navbarSvc.showFooter();
      this.element = el.nativeElement;
  }
  ngOnInit(): void {
      this.isVisibleSignUpPopUp = false;
     
      console.error('signup init------------');
      if (!this.id) {
          console.error('modal must have an id');
          return;
      }


      console.log("this.element",this.element);
      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      document.body.appendChild(this.element);

      // close modal on background click
      this.element.addEventListener('click', el => {
          if (el.target.className === 'login-ui' || this.isVisibleSignUpPopUp == true) {
              this.close();
          }
      })
      this.messageService.serviceType.subscribe((response) => {
       console.log(response);
        if (response) {
            this.setSignupFormData(response);
        }
        })

      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
       this.initializeSignUpForm();
  }


    setSignupFormData(data) {
        // console.log(this.isVisiblePassword,"ksdfkdsafnkand")
        this.Form.controls.email.setValue(data);
        // if(localStorage.getItem("userData")){
        //     let email = JSON.parse(localStorage.getItem("userData")).email;
        //     console.log(email);
        //     if (email) {
        //         this.Form.controls.email.setValue(data.email)
        //     }
        // }
    }

 
  initializeSignUpForm() {
      this.Form= this.formBuilder.group({
          'email': new FormControl('', [Validators.required]),
          //'phoneNumber': new FormControl('', [Validators.required])
      });
  }

  get f() { return this.Form.controls; }

  onSubmitForm() {
      this.isVisibleSignUpPopUp = false;
      var valid = this.phoneNumberComp.phoneInput;
      this.submitted = true;
      console.log(this.Form)
      if (this.Form.invalid) {
          return;
      }
      if (!valid) {
            this.isPhoneNumberInvalid = true;
            this.errorlog = true;
            this.errormsg = 'Enter valid phone number';
            this.spinner.hide();
            return;
    }
      console.log(valid)
      this.spinner.show();

          this.obj = {
              "email": this.Form.controls.email.value,
              "countryENCode": (this.phoneNumberComp.phoneInput.s.iso2).toUpperCase(),
              "countryCode": '+' + this.phoneNumberComp.phoneInput.s.dialCode,
              "phoneNo": this.phoneNumberComp.phoneInput.a.value,
              "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220",
              "streetName": "",
          }
          this.accountSvc.signUp2(this.obj).subscribe((response) => {
            //   this.isVisibleSignUpPopUp = true;
            //   document.body.classList.remove('login-ui-open');
            //   this.spinner.hide();
            //   this.Form.reset();
            //   $("#success_modal").modal('show');
                this.isVisibleSignUpPopUp = true;
                this.errorlog = false;
                this.errormsg = '';
                document.body.classList.remove('login-ui-open');
                this.spinner.hide();
                this.Form.reset();
                this.phoneNumberComp.phoneInput.a.value = '';
                this.doLoginData(response);
          }, (error: { error: { message: string; statusCode: number }; }) => {
              this.spinner.hide();
              this.errorlog = true;
              this.errormsg = error.error.message;
              return;
          })

      
  }

    openOTPModal(id: string) {
    this.close();
        console.log("this.openOTPModal")
        this.otpService.open(id);
    }

    closeOTPModal(id: string) {
        this.otpService.close(id);
    }

     doLoginData(response: any) {
        this.isClickBackGround=false;
        this.spinner.show();
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("userData", JSON.stringify(response.data));
        if(!response.data.isVerified){
            this.openOTPModal('custom-modal-60')
            this.spinner.hide();
        }else{
            this.navbarSvc.loginVar = true;
            this.router.navigateByUrl('/hire-cleaner-in-London');
            this.spinner.hide();
        }
    }


  onCloseModal() {
      this.isClickBackGround=false;
      this.element.style.display = 'none';
  }


 

  ngOnDestroy() {

  }

  // open modal
  open(): void {
      //this.phoneNumberComp.phoneInput.a.value='';
      //this.isClickBackGround=true;
      this.submitted = false;
      this.isVisibleSignUpPopUp = false;
      this.element.style.display = 'block';
      document.body.classList.add('login-ui-open');
  }

  // close modal
  close(): void {
      this.isClickBackGround=false;
      this.Form.reset();
      this.element.style.display = 'none';
      document.body.classList.remove('login-ui-open');
  }


openPhoneModal(id: string) {
    this.close();
    this.signupService.open(id);
}

closePhoneModal(id: string) {
    this.signupService.close(id);
}


  openLoginModal(id: string) {
      this.close();
      this.loginService.open(id);
  }

  closeLoginModal(id: string) {
      this.loginService.close(id);
  }
  // login with gmail
 

}
