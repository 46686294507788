import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaidsTermsAndConditionsComponent } from './maid-term.component';
import { MaidsTermsAndConditionsRoutingModule } from './maid-term.routing';



@NgModule({
  declarations: [MaidsTermsAndConditionsComponent],
  imports: [
    CommonModule,
    MaidsTermsAndConditionsRoutingModule
  ]
})
export class MaidsTermsAndConditionsModule { }
