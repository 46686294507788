import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
//import { ModalService } from '../../../../account/login/login-component';
import { NavbarService } from "../../../../services/navrbar.service";
import { LoginService } from "../../../../account/login";
import { SignupService } from "../../../../account/signup/signup.service";
import { MessageService } from "../../../../services/message.service";
import { environment } from 'src/environments/environment';
  
declare var AOS: any;
declare var jQuery: any;

@Component({
  selector: "navbar-ui",
  templateUrl: "./navbar.html",
  styleUrls: ["./navbar.css", "../../../../shared-css/static-page.css"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  userName: any;
  userNameOfuser: any;
  ashish: any[] = [];
  isClickBackGround: boolean;
  public get navbarSvc(): NavbarService {
    return this._navbarSvc;
  }
  public set navbarSvc(value: NavbarService) {
    this._navbarSvc = value;
  }
  public showNavbar: boolean;
  public isOverlay: boolean;
  public userData: any = JSON.parse(localStorage.getItem("userData"));
  public loginType: string = localStorage.getItem("loginType");
  webURL:string;
  closeResult: string;

  // constructor(private el: ElementRef, private renderer: Renderer2, private router: Router,private modalService: ModalService) {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private _navbarSvc: NavbarService,
    private modalService: LoginService,
    public signupService: SignupService,
    public messageService: MessageService
  ) {

    console.log(" +++  ENTER IN Navbar Constructor ++");
  }

  ngOnInit() {
    console.log(" +++  ENTER IN Navbar Component ++");
    console.log(localStorage.getItem("accessToken"))
    // debugger
    // if (localStorage.getItem("accessToken") ==  null || localStorage.getItem("accessToken") == '') {
    //     this.navbarSvc.loginVar = false;
    //     this.showHideNavbar("hide");   
    //     // window.location.href='/'; // nitin   
    //     // this.openModal("custom-modal-12");
    //     // this.router.navigateByUrl('');
    //     this.router.navigateByUrl("/");
    // } else {
    //     this.navbarSvc.loginVar = true;
    //     this.router.navigateByUrl('/hire-cleaner-in-London');
    // }
    if(localStorage.getItem("accessToken") && this.userData.profileComplete && localStorage.getItem("isVerified") == 'true'){
      this.navbarSvc.loginVar = true;
      this.router.navigateByUrl('/hire-cleaner-in-London');
    }
    
    this.webURL = environment.webBaseURL+'hire-cleaner-in-London'
        
    this.messageService.serviceType.subscribe((response) => {
      if (response) {
        console.log(
          response +
            "_________________________________--- MESG SERVICE -------------------------+++++++++++++++++"
        );
        this.userNameOfuser = response;
        console.log(this.userNameOfuser ,  " this.userNameOfuser")
        if(this.userNameOfuser != undefined){
            this.initLocalStorage(this.userNameOfuser);
        }
        
      }
    });
    this.initLocalStorage('');

    // debugger;
    //this.jqueryInt();
    jQuery(document).ready(function ($) {
      var self = this;
      ("use strict");
      $(window).resize(function () {
        var $this = $(this),
          w = $this.width();

        if (w > 768) {
          if ($("body").hasClass("offcanvas-menu")) {
            $("body").removeClass("offcanvas-menu");
          }
          self.isOverlay = false;
        }
      });
    });
  }

  logout() {
    console.log(" +++  ENTER IN LOGOUT ++");
    this.onCloseModal();
    // if(confirm("Do you want to logout?")) {
    setTimeout(() => {
      this.showHideNavbar("hide");
    }, 500);
    this._navbarSvc.loginVar = false;
    localStorage.clear(); // Nitin
    this.router.navigateByUrl("/");
    // this.openModal("custom-modal-12");
    // }
  }

  redirectToHome() {
    console.log("ashish---------------------------------------------");
    console.log(" +++  ENTER IN ridrect TO Home ++");
    
    localStorage.removeItem("isMyfavouriteMaid");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isReschedule");
    localStorage.removeItem("maidId");
    localStorage.removeItem("amount");
    localStorage.removeItem("loginType");
    localStorage.removeItem("dayLength");
    this.router.navigateByUrl("/hire-cleaner-in-London");
  }

  jqueryInt() {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
    });
    var self = this;
    jQuery(document).ready(function ($) {
      "use strict";
      $(".js-clone-nav").each(function () {
        var $this = $(this);
        $this
          .clone()
          .attr("class", "site-nav-wrap")
          .appendTo(".site-mobile-menu-body");
      });
      setTimeout(function () {
        var counter = 0;
        $(".site-mobile-menu .has-children").each(function () {
          var $this = $(this);
          $this.prepend('<span class="arrow-collapse collapsed">');
          $this.find(".arrow-collapse").attr({
            "data-toggle": "collapse",
            "data-target": "#collapseItem" + counter,
          });
          $this.find("> ul").attr({
            class: "collapse",
            id: "collapseItem" + counter,
          });
          counter++;
        });
      }, 1000);
      $("body").on("click", ".arrow-collapse", function (e) {
        var $this = $(this);
        if ($this.closest("li").find(".collapse").hasClass("show")) {
          $this.removeClass("active");
        } else {
          $this.addClass("active");
        }
        e.preventDefault();
      });

      $(window).resize(function () {
        var $this = $(this),
          w = $this.width();

        if (w > 768) {
          if ($("body").hasClass("offcanvas-menu")) {
            $("body").removeClass("offcanvas-menu");
          }
          self.isOverlay = false;
        }
      });

      // $('body').on('click', '.js-menu-toggle', function (e) {
      //     var $this = $(this);
      //     e.preventDefault();

      //     if ($('body').hasClass('offcanvas-menu')) {
      //         $('body').removeClass('offcanvas-menu');
      //         $this.removeClass('active');
      //     } else {
      //         $('body').addClass('offcanvas-menu');
      //         $this.addClass('active');
      //     }
      // })

      $("body").on("click", ".link", function (e) {
        var $this = $(this);
        e.preventDefault();
        setTimeout(() => {
          self.redirect(e.currentTarget.id);
          self.showHideNavbar("hide");
        }, 500);
      });
      $("body").on("click", ".overlay-dv", function (e) {
        var $this = $(this);
        e.preventDefault();
        setTimeout(() => {
          self.showHideNavbar("hide");
        }, 100);
      });
    });
  }

  initLocalStorage(uName:string) {
    // debugger;
    console.log(" +++  ENTER IN initLocalStorage ++");

    // this.ashish = JSON.parse(localStorage.getItem('userData')).fullName;
    // console.log(this.ashish)
    // this.userName =this.ashish;
    console.log(localStorage.getItem("userData") ,  " ++ user Data ++" )
    console.log(localStorage.getItem("profileUpdate") ,  " ++ profileUpdate Data ++" )
    if(uName){
      // debugger;
      this.userName = uName
    } else {
      // debugger;
      this.userName = JSON.parse(localStorage.getItem("profileUpdate"))
      ? JSON.parse(localStorage.getItem("profileUpdate")).fullName
      : JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData")).fullName
      : "User";
    }
  
    console.log(this.userName, "====  USERNAME ");
    console.log(uName, "====  UNAME ");
    // debugger;
  }

  showHideNavbar(action: string) {
    // console.log("heeeeeeeeeeeeeeeeee-----------------------")
    switch (action) {
      case "show":
        var html = this.el.nativeElement.childNodes[0];
        html.setAttribute("style", "right: 20em");
        this.showNavbar = true;
        this.isOverlay = true;
        break;
      case "hide":
        var html = this.el.nativeElement.childNodes[0];
        console.log("ashish------------======================");
        html.setAttribute("style", "right: 0em");
        this.showNavbar = false;
        this.isOverlay = false;
        break;
    }
  }

  // redirect(page: string) {
  //     if (page != "" && page != "/login") {
  //         setTimeout(() => {
  //             this.showHideNavbar('hide');
  //         }, 500);
  //         this.router.navigateByUrl(page);
  //     }
  //     else {
  //         this.showHideNavbar('hide');
  //     }
  // }

  redirect(page: string) {
    console.log(" +++  ENTER IN redirect ++" + page);
    localStorage.removeItem("bookingTabType");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isReschedule");
    localStorage.removeItem("maidId");
    localStorage.removeItem("amount");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("isBookAgain");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isMyfavouriteMaid");
    localStorage.removeItem("isCancelPayemnt");
    localStorage.removeItem("maidId");
    localStorage.removeItem("isBack");
    localStorage.removeItem("bookingTabType");

    
    setTimeout(() => {
      this.showHideNavbar("hide");
    }, 500);
    this.router.navigateByUrl(page);
  }

  redirectToPage(page: string) {
    console.log(" +++  ENTER IN redirectToPage ++" + page);
    localStorage.removeItem("bookingTabType");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isReschedule");
    localStorage.removeItem("maidId");
    localStorage.removeItem("amount");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("isBookAgain");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isMyfavouriteMaid");
    localStorage.removeItem("isCancelPayemnt");
    localStorage.removeItem("maidId");
    localStorage.removeItem("isBack");
    localStorage.removeItem("bookingTabType");
    // alert("this ica called");
    this.router.navigateByUrl(page);
  }

  ngOnDestroy() {}

  openModal(id: string) {
    this.showHideNavbar("hide");
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openSignUpModal(id: string) {
    this.showHideNavbar("hide");
    this.signupService.open(id);
  }

  closeSignUpModal(id: string) {
    this.signupService.close(id);
  }

  onCloseModal() {
    this.isClickBackGround = false;
  }
  onOpenModal() {
    this.isClickBackGround = true;
  }
}
