import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedByAppModule } from '../../shared-by-app/shared-module';
import { BsModule } from '../../shared-by-app/modules/bs.module';
import { ForgotRoutingModule } from './forgot.routing';
import { ForgotComponent } from './forgot-component';


@NgModule({
  declarations: [ForgotComponent],
  imports: [
    CommonModule,
    ForgotRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedByAppModule,
    BsModule
  ],

  exports: [ForgotComponent],
})
export class ForgotModule { }
