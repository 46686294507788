import { Injectable } from "@angular/core";
import { LoginModel } from '../../account/login/login.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AllEndPoints } from '../../shared-models/end-points';
import 'rxjs/add/operator/map';
import { RegisterationModel } from '../../account/register/register.model';
import { SignupModel } from 'src/app/account/signup/signup.model';
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase/app";
import { NewLoginModel } from "src/app/account/new-login/new-login.model";

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public noHeaders: HttpHeaders;
    public Headers: HttpHeaders;

    // private afAuth: AngularFireAuth
    constructor(private http: HttpClient, private afAuth: AngularFireAuth) {
        this.noHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Header': 'True' });
        this.Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if(localStorage.getItem('accessToken')){
            this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
            this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
            }
    }



    loginUser(data: LoginModel) {
        const url = AllEndPoints.Accounts.loginUser;
        data.uniquieAppKey = AllEndPoints.uniqueAppKey.uniqueKey;
        data.deviceToken = "website";
        data.deviceType = "WEB";
        data.timeZone = 'Europe/London'
        return this.http.put(url, data, { headers: this.noHeaders })
            .map(res => res)
    }
    loginUserWithGoogle(data) {
        const url = AllEndPoints.Accounts.loginUserWithGoogle;
        data.uniquieAppKey = AllEndPoints.uniqueAppKey.uniqueKey;
        data.deviceToken = "website";
        data.deviceType = "WEB";
        // data.deviceType = "IOS";
        return this.http.put(url, data, { headers: this.noHeaders })
            .map(res => res)
    }

    loginUserWithGoogleCheck(data) {
        const url = AllEndPoints.Accounts.loginUserWithGoogleCheck;
        data.uniquieAppKey = AllEndPoints.uniqueAppKey.uniqueKey;
        data.deviceToken = "website";
        // data.deviceType = "IOS";
        data.deviceType = "WEB";
        return this.http.post(url, data, { headers: this.Headers  })
            .map(res => res)

    }

    contactUs(data) {
        const url = AllEndPoints.Accounts.contactUs;
        return this.http.post(url, data, { headers: this.noHeaders })
            .map(res => res)
    }
    verifyMail(data) {
        const url = AllEndPoints.Accounts.mailVerification;
        return this.http.post(url, data, { headers: this.noHeaders})
            .map(res => res)
    }

    signUp2(data) {
        const url = AllEndPoints.Accounts.loginUserWithGoogleCheck;
        return this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
    }

    signUpUser(data) {
        const url = AllEndPoints.Accounts.signUpUser;
        return this.http.post(url, data, { headers: this.noHeaders })
            .map(res => res)
    }
    forgotPassword(data) {
        const url = AllEndPoints.Accounts.forgotPassword;
        return this.http.post(url, data, { headers: this.noHeaders })
            .map(res => res)
    }
    verifyOTP(data){
        const url = AllEndPoints.Accounts.verifyOTP;
        console.log(url,data)
        let response = this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
            console.log("response",response)
            return response;
    }
    resendOTP(data){
        const url = AllEndPoints.Accounts.resendOTP;
        console.log(url,data)
        let response = this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
            console.log("response",response)
            return response;
    }
    getAgencies() {
        const url = AllEndPoints.Accounts.getAgencies(AllEndPoints.uniqueAppKey.uniqueKey);
        return this.http.get(url, { headers: this.noHeaders })
            .map(res => res)
    }
    addMaidDetail(data: RegisterationModel) {
        const url = AllEndPoints.Accounts.addMaidDetail;
        data.uniquieAppKey = AllEndPoints.uniqueAppKey.uniqueKey;
        data["timeZone"] = 'Europe/London';
        return this.http.post(url, data, { headers: this.noHeaders })
            .map(res => res)
    }

    sendFireBaseOTP(phone: string): Promise<any> {
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible'
        });
        return this.afAuth.auth.signInWithPhoneNumber(phone, appVerifier);
    }
    verifyFireBaseOTP(verificationId: string, code: string): Promise<any> {
        const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
        return this.afAuth.auth.signInWithCredential(credential);
    }

    getAllBuildings() {
        const url = AllEndPoints.Accounts.getBuildings(AllEndPoints.uniqueAppKey.uniqueKey);
        return this.http.get(url, { headers: this.noHeaders })
            .map(res => res)
    }

    signUpUserByBuilding(data) {
        const url = AllEndPoints.Accounts.signUpUserByBuilding;
        return this.http.post(url, data, { headers: this.noHeaders })
            .map(res => res)
    }

    emailLoginByBuilding(data: NewLoginModel) {
        const url = AllEndPoints.Accounts.emailLoginByBuilding;
        data.uniquieAppKey = AllEndPoints.uniqueAppKey.uniqueKey;
        // data.deviceToken = "website";
        data.deviceType = "WEB";
        data.timeZone = 'Europe/London';
        // console.log(data);
        // return;
        return this.http.put(url, data, { headers: this.noHeaders })
            .map(res => res)
    }
}