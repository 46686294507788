import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavbarService } from '../../services/navrbar.service';

@Component({
  selector: 'app-maids-terms-and-conditions',
  templateUrl: './maid-term.html',
  styleUrls: ['./maid-term.css', '../../shared-css/static-page.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class MaidsTermsAndConditionsComponent implements OnInit {

  constructor(private navbarSvc: NavbarService) {
    // this.navbarSvc.showSimpleNavbar();
    // this.navbarSvc.showFooter();
    this.navbarSvc.hideBothNavbar()
    this.navbarSvc.hideFooter();    
  }

  ngOnInit() {
    
  }

}
