import { Component, ViewChild, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { SignupModel } from './signup.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from 'src/app/services/account/account.service';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navrbar.service';
import { SignupService } from './signup.service';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { PhoneNumberComponent } from '../../shared-by-app/components/phone-number/phone-number.component';
import { LoginService } from '../login/login.service';
import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialUser
} from 'ng4-social-login';
import { DatePipe } from '@angular/common';
import { MessageService } from 'src/app/services/message.service';
import { OtpService } from "../otp/otp.service";
import firebase from "firebase/app";
import { AngularFireAuth } from '@angular/fire/auth';

declare var $: any;
declare var gtag

@Component({
    selector: "signup-ui",
    templateUrl: "./signup.html",
    styleUrls: ['./signup.css', './signup.less'],
    encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit, OnDestroy {
    @ViewChild(PhoneNumberComponent, { static: false }) phoneNumberComp: PhoneNumberComponent;

    signUpForm: FormGroup;
    submitted = false;
    alpha:boolean=false;
    loginType:string="";
    exa:any=1;
    isContact:boolean=false;
    isEmailDisabled:boolean=false;
    errorlog = false;
    first_name2:string;
    last_name2:string;
    errormsg: string = '';
    @Input() id: string;

    private element: any;
    obj: any = {};
    isPhoneNumberInvalid: boolean;
    isVisibleSignUpPopUp: boolean;
    private user: SocialUser;
    isVisiblePassword: boolean;
    changeType: boolean = true;
    visible: boolean = true;
    userGoogleSignupData: any;
    object: any;
    isClickBackGround: boolean;
    showPassword: boolean;
    phoneRequired: boolean;
    otpOpen: boolean = false;
    // public recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    windowRef: any;
    window: any;
    constructor(
        private formBuilder: FormBuilder,
        private accountSvc: AccountService,
        private router: Router,
        private navbarSvc: NavbarService,
        private modalService: SignupService,
        private el: ElementRef,
        public commonService: CommonService,
        private spinner: NgxSpinnerService,
        private loginService: LoginService,
        private authService: AuthService,
        public messageService: MessageService,
        public otpService:OtpService,
        private afAuth: AngularFireAuth
        // private datePipe: DatePipe
        // private modal: NgbModal
    ) {
        this.navbarSvc.showSimpleNavbar();
        this.navbarSvc.showFooter();
        this.element = el.nativeElement;
        
    }
    ngOnChanges()
    {
    }
    ngOnInit(): void {
        // localStorage.clear(); // Nitin
        this.getLocalTimezone();
        this.navbarSvc.loginVar = false;
        this.isEmailDisabled = false;
        this.isVisibleSignUpPopUp = false;
        this.isVisiblePassword = false;
        this.messageService.googleSignInData.subscribe((response) => {
            console.log(response);
            if (response) {
                this.isVisiblePassword = true;
                this.exa = 2
                // this.isEmailDisabled = true;
                this.setSignupFormData(response);
                this.isEmailDisabled = true;
            }
        })
        this.initializeSignUpForm();

        this.window = this.otpService.windowRef;
        this.windowRef = this.otpService.windowRef;
        // Retrieve the isSocialLogin value
        this.messageService.isSocialLogin$.subscribe(isSocialLogin => {
            // Use the value as needed
            // alert(isSocialLogin);
            if(isSocialLogin == true) {
                this.isEmailDisabled = true;
                this.isVisiblePassword = true;
                this.signUpForm.controls.email.setValue(JSON.parse(localStorage.getItem("userData")).email)
            }
            this.spinner.hide();
        });
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);



        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'login-ui' || this.isVisibleSignUpPopUp == true) {
                this.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
        this.initGoogleFb();

        // this.windowRef = this.otpService.windowRef
        // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        // this.windowRef.recaptchaVerifier.render();
        // console.log(this.isVisiblePassword,"ksdfkdsafnkand")
    }

    initGoogleFb() {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
        });
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
        });
    }

    setSignupFormData(data) {
        // console.log(this.isVisiblePassword,"ksdfkdsafnkand")
        if (data) {
            // const [firstName, lastName] = data.fullName.split(' ');
            // this.signUpForm.controls.first_name.setValue(firstName)
            // this.signUpForm.controls.last_name.setValue(lastName)
            // this.signUpForm.controls.full_name.setValue(data.fullName)
            this.signUpForm.controls.email.setValue(data.email)
        }
    }
    // get f() { return this.signUpForm.controls; }
    private getLocalTimezone(): string {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('timezone12', timezone);
        return timezone;
      }
      
    signInWithGoogle(): void {
        console.log('@@@@@')
        // localStorage.clear(); // Nitin
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
                this.user = userData;
                console.log(` google signin user data ${userData}`);
                if (this.user) {
                    this.loginType="GOOGLE"
                    this.googleLogin(this.user);
                    const [firstName, lastName] = this.user.name.split(' ');
                    console.log(firstName)
                    console.log(lastName)
                    this.first_name2 = firstName;
                    this.last_name2 =lastName;
                }
        })
        .catch((e) =>{
            console.log(`error on google sigbbin ${e}`);
        });
        // console.log(this.user, "fdfjskgnkrgktgfrgrb")
    }
    viewpass() {
        this.visible = !this.visible;
        this.changeType = !this.changeType;
      }
    signInWithFacebook(): void {
        // localStorage.clear(); // nitin
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
            if (this.user) {
                this.loginType="FACEBOOK"
                this.googleLogin(this.user);
               
            }
        });
        // console.log(this.user, "fdfjskgnkrgktgfrgrb")
    }

    initializeSignUpForm() {
      
            this.signUpForm = this.formBuilder.group({
                'first_name': new FormControl('', [Validators.required]),
                'last_name': new FormControl('', [Validators.required]),
                'email': new FormControl('', [Validators.required, Validators.email]),
                'password': new FormControl('', [Validators.required]),
            });
       
    }

    get f() { 
        return this.signUpForm.controls; 
    }

    
    openOTPModal(id: string) {
        this.close();
        console.log("this.openOTPModal")
        this.otpService.open(id);
    }

    closeOTPModal(id: string) {
        this.otpService.close(id);
    }

    validateEmail(email: string): boolean {
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
        return emailRegex.test(email);
    }      
    
    signUpFormSubmit() {
        this.spinner.show();
        console.log()
        this.submitted=true;
        if(this.exa == 2)
        {
            // this.phoneRequired = false;
            this.isContact=false;
        }else
        {  
            // this.phoneRequired = true;
            this.isContact=true;
            // setTimeout(()=>{
            //     this.isContact = false;
            // }, 8000)
        }
        var valid = this.phoneNumberComp.phoneInput.isValidNumber();
        var valid = this.phoneNumberComp.phoneInput;
        console.log('valid', valid);
        if(this.exa != 2)
        {
            if (!this.signUpForm.controls.first_name.value) {
                this.isPhoneNumberInvalid = true;
                // this.errorlog = true;
                // this.errormsg = 'Enter full name';
                this.spinner.hide();
                return;
            }
            if (!this.signUpForm.controls.last_name.value) {
                this.isPhoneNumberInvalid = true;
                // this.errorlog = true;
                // this.errormsg = 'Enter full name';
                this.spinner.hide();
                return;
            }
        }
        if (!this.signUpForm.controls.email.value) {
            this.isPhoneNumberInvalid = true;
            this.errorlog = true;
            // this.errormsg = 'Enter valid email';
            this.spinner.hide();
            return;
        } 
        else {
            console.log('else');
            const emailValue = this.signUpForm.controls.email.value;
            console.log('emailValue:', this.validateEmail(emailValue));

            if(!this.validateEmail(emailValue)) {
                console.log('if');
                this.isPhoneNumberInvalid = true;
                this.errorlog = false;
                this.spinner.hide();
                return;
            }
            else {
                console.log('else else');
            }
        }
        if (!valid) {
            // this.phoneRequired = true;
            this.isPhoneNumberInvalid = true;
            this.errorlog = true;
            // this.errormsg = 'Enter valid phone number';
            this.spinner.hide();
            return;
        }
        if (!this.isVisiblePassword) {
            if (!this.signUpForm.controls.password.value) {
                this.isPhoneNumberInvalid = true;
                this.errorlog = true;
                // this.errormsg = 'Enter password';
                this.spinner.hide();
                return;
            }
            console.log("------------ phoneNumberComp ---------------")
            console.log(this.phoneNumberComp.phoneInput)
            this.obj = {
                "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220",
                "fullName": this.signUpForm.controls.first_name.value + " " +  this.signUpForm.controls.last_name.value,
                "firstName": this.signUpForm.controls.first_name.value,
                "lastName": this.signUpForm.controls.last_name.value,
                "email": this.signUpForm.controls.email.value,
                "password": this.signUpForm.controls.password.value,
                "deviceToken": "website",
                'loginType' : "NORMAL",
                "countryENCode": this.phoneNumberComp.phoneInput.s.iso2.toUpperCase(),
                "countryCode": '+' + this.phoneNumberComp.phoneInput.s.dialCode,
                "phoneNo": this.phoneNumberComp.phoneInput.a.value,
                "deviceType": "WEB"
            }

            console.log("----- signup params -----------")
            console.log(this.obj)
            // return;
            // this.accountSvc.signUpUser({}).subscribe((response) => {

            if(this.phoneNumberComp.phoneInput.a.value != "") {
                console.log('asdsa', this.phoneNumberComp.phoneInput.a.value)
                this.accountSvc.signUpUser(this.obj).subscribe((response: any) => {
    
                console.log('respnse', response);
                if(response.data.profileComplete) {
                    this.isVisibleSignUpPopUp = true;
                    this.errorlog = false;
                    this.errormsg = '';
                    document.body.classList.remove('login-ui-open');
                    // this.spinner.hide();
                    this.signUpForm.reset();
                    // this.phoneNumberComp.phoneInput.a.value = '';
                    this.messageService.serviceType.next(response.data.fullName)
                    localStorage.setItem("signUserData4", 'true');
                    this.doLoginData(response);
                } else {
                    localStorage.setItem("accessToken", response.data.accessToken);
                    localStorage.setItem("userData", JSON.stringify(response.data));
                    this.sendOTP();
                    // setTimeout(() => {
                        // this.spinner.hide();
                    // }, 3000)
                }
                    
                   // $("#custom-modal-20").modal('show');
                    // console.log(response, "inside response")
                }, (error: { error: { message: string; statusCode: number; responseType: string }; }) => {
                    this.spinner.hide();
                    console.log(error.error)
                    if(error.error.responseType == 'INVALID_EMAIL') {
                        this.errorlog = false;
                        this.errormsg = '';
                        return;
                    }
                    else if(error.error.responseType == 'PHONE_NO_MISSING') {
                        this.errorlog = false;
                        this.errormsg = '';
                        return;
                    }
                    else {
                        this.errorlog = true;
                        this.errormsg = error.error.message;
                        // return;
                    }
                    return;
                })
            }
            else {
                this.isContact = true;
                this.spinner.hide();
                return;
            }

        } else {
            this.googleLoginCheck();
        }
    }
    

    keyPressAlphanumeric1(event:any) {
        console.log(event.target.value)
        var val=event.target.value
        // Check if the entered key is a digit
        event.target.value = val.replace(/\D/g, '');


        if(val.length >= 1)
        {
            // this.phoneRequired = false;
            this.isContact=false
            this.exa = 2
        }else
        {   
            // this.phoneRequired = true;
            this.isContact=true
            this.exa = 1;
        }
        // var inp = String.fromCharCode(event.keyCode);
        // if (!/[0-9]/.test(inp)) {
        //   return true;
        // } else {
        //   event.preventDefault();
        //   this.alpha = true;
        //   setTimeout(() => {
        //     this.alpha = false;
        //   }, 4000)
        //   return false;
        // }
      }
    onCloseModal() {
        this.isClickBackGround=false;
        this.element.style.display = 'none';
    }

    clearRecaptchaVerifier() {
        if (this.window.recaptchaVerifier) {
            this.window.recaptchaVerifier.clear();
            this.window.recaptchaVerifier = null;
        }
    }
    
    sendOTP() {
        setTimeout(() => {
            this.spinner.hide();
        }, 3000);
        console.log("12213");
        if(!this.window.recaptchaVerifier) {
            this.window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { 'size': 'invisible' });
        }
        const appVerifier = this.window.recaptchaVerifier;
        let countryCode;
        let phoneNo;
        let phone;
        if(localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).countryCode && JSON.parse(localStorage.getItem('userData')).phoneNo) {
            countryCode = JSON.parse(localStorage.getItem('userData')).countryCode;
            phoneNo = JSON.parse(localStorage.getItem('userData')).phoneNo;
        }
        phone = countryCode + phoneNo;
        if(localStorage.getItem('phoneNo')) {
            phone = localStorage.getItem('phoneNo');
        }
        // alert(phone);
        // return;
        // const phone = "+919646500000";
        firebase.auth().signInWithPhoneNumber(phone, appVerifier).then(result => {
            // debugger;
            this.spinner.hide();
            this.openOTPModal('custom-modal-60');
            this.otpOpen = true;
            this.windowRef.confirmationResult = result;
            console.log(result);
            localStorage.setItem("verificationId", JSON.stringify(result))
            this.clearRecaptchaVerifier();

            // setTimeout(() => {
                // this.spinner.hide();
            // }, 3000)
          })
          .catch( error => {
            // return;
            // debugger;
            this.spinner.hide();
            this.otpOpen = false;
            console.log('error', error);
            if(error.code == 'auth/invalid-phone-number') {
                // this.spinner.hide();
                $('#invalidPhone').modal('show');
            // this.modalService.open('invalidPhone');
            }
            localStorage.clear();
            // appVerifier.clear()
            this.clearRecaptchaVerifier();

            //   this.window.recaptchaVerifier.render().then(function(widgetId) {
            //     this.window.grecaptcha.reset(widgetId);
            //   });
            //   this.window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { 'size': 'invisible'});
            //   return;
          } );
    }

    doLoginData(response: any) {
        // this.spinner.show();
        this.isClickBackGround=false;
        document.body.classList.remove('login-ui-open');
        console.log('dologindata', response);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("userData", JSON.stringify(response.data));
        localStorage.setItem("signUserData", 'true');
        localStorage.setItem("isVerified", response.data.isVerified);
        localStorage.setItem("emailVerified", response.data.emailVerified);

        console.log(response.data.isVerified ,  " Is Verified ++")
         // Google Analytics
        gtag('event', 'form_submit_register_success', {
            'event_category': 'form_submit',
            'event_label': 'Signup Page',
            'value': 'form_submit_register_success'   })
        // debugger;
        this.navbarSvc.loginVar = true;
        this.router.navigateByUrl('/hire-cleaner-in-London');
        // setTimeout(() => {
            this.spinner.hide();
        // }, 2000)
    }

    ngOnDestroy() {

    }

    // open modal
    open(): void {
        this.phoneNumberComp.phoneInput.a.value='';
        this.signUpForm.controls.password.reset('');
        // this.signUpForm.reset();
        this.submitted = false;
        this.isClickBackGround=true;
        this.isVisibleSignUpPopUp = false;
        this.errorlog = false;
        this.errormsg = '';
        this.element.style.display = 'block';
        document.body.classList.add('login-ui-open');
    }

    // close modal
    close(): void {
        this.isClickBackGround=false;
        this.signUpForm.reset();
        this.isVisiblePassword = false;
        this.isVisibleSignUpPopUp = false;
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
    }

    openLoginModal(id: string) {
        this.isContact=false
        this.close();
        this.loginService.open(id);
    }

    closeLoginModal(id: string) {
        this.loginService.close(id);
    }
    // login with Facebook or Google
    googleLogin(userdata) {
        console.log('userdata', userdata);
        const [firstName, lastName] = userdata.name.split(' ');
        console.log(firstName)
        console.log(lastName)
        this.first_name2 = firstName;
        this.last_name2 =lastName;
        this.object = {
            'facebookId': userdata.id,
            'fullName': userdata.name,
            'firstName':firstName,
            'lastName':firstName,
            'email': userdata.email?userdata.email:"",
           'loginType' : this.loginType
        }
        console.log(this.object)
        this.spinner.show();
        this.accountSvc.loginUserWithGoogle(this.object).subscribe(
            (data: any) => {
                localStorage.setItem("accessToken", data.data.accessToken);
                localStorage.setItem("userData", JSON.stringify(data.data));
                if (data.data.profileComplete) {
                    // this.spinner.hide();
                    this.isVisiblePassword = false;
                    this.isEmailDisabled = true;
                    this.messageService.serviceType.next(data.data.fullName)
                    localStorage.setItem("isFbGmailLogin", "true")
                    this.doLoginData(data);
                } else {
                    this.spinner.hide();
                    this.messageService.googleSignInData.next(data.data)
                    this.messageService.serviceType.next(data.data.fullName)
                    
                    //  this.openSignUpModal('custom-modal-13')
                }

            },
            (error: { error: { message: string; statusCode: number }; }) => {
                this.spinner.hide();
                this.errorlog = true;
                this.errormsg = error.error.message;
                return;
            }
        )
    }
    // googleLogin() {
    //     this.spinner.show();
    //     this.object = {
    //         'facebookId': "12793020359",
    //         'fullName': "Anubhav Singla",
    //         'firstName':"Anubhav",
    //         'lastName':"Singla",
    //         'email': "anubhav19.xpe12rge@gmail.com",
    //        'loginType' : "GOOGLE"
    //     }
    //     // console.log(this.object);
    //     // return;
    //     this.accountSvc.loginUserWithGoogle(this.object).subscribe(
    //         (data: any) => {
    //             localStorage.setItem("accessToken", data.data.accessToken);
    //             localStorage.setItem("userData", JSON.stringify(data.data));
    //             if (data.data.profileComplete) {
    //                 debugger;
    //                 // this.spinner.hide();
    //                 this.isVisiblePassword = false;
    //                 this.isEmailDisabled = true;
    //                 this.messageService.serviceType.next(data.data.fullName)
    //                 localStorage.setItem("isFbGmailLogin", "true")
    //                 this.doLoginData(data);
    //             } else {
    //                 debugger;
    //                 this.spinner.hide();
    //                 this.messageService.googleSignInData.next(data.data)
    //                 this.messageService.serviceType.next(data.data.fullName)
    //                 //  this.openSignUpModal('custom-modal-13')
    //             }

    //         },
    //         (error: { error: { message: string; statusCode: number }; }) => {
    //             this.spinner.hide();
    //             this.errorlog = true;
    //             this.errormsg = error.error.message;
    //             return;
    //         }
    //     )
    // }


    //Signup 2 API after Facebook or Google login/signup 
    googleLoginCheck() {
        debugger;
        // this.spinner.show();
        this.messageService.setIsSocialLogin(false);
        this.object = {
            "buildingName": "",
            "city": "",
            "nationalId": "",
            "streetName": "",
            "villaName": "",
           "country": "",
          "documentPicURL": {},
            "countryCode": '+' + this.phoneNumberComp.phoneInput.s.dialCode,
            "countryENCode": this.phoneNumberComp.phoneInput.s.iso2.toUpperCase(),
            "deviceToken": "website",
            "deviceType": "WEB",
            // "deviceType": "WEB",
            "email": this.signUpForm.controls.email.value,
            "phoneNo": this.phoneNumberComp.phoneInput.a.value,
            // "timeZone": "Asia/Kolkata",
            "timeZone": this.getLocalTimezone(),
            
            "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220"
        }
        console.log(this.object);
        // return;
        // this.spinner.show();
        if(this.phoneNumberComp.phoneInput.a.value != "") {
            this.accountSvc.loginUserWithGoogleCheck(this.object).subscribe(
                (data: any) => {
                    console.log('data signup2', data);
                    // return;
                    console.log(data.data.profileComplete)
                    if(data.data.profileComplete)
                    {
                        this.isVisiblePassword = false;
                        this.errorlog = false;
                        this.errormsg = '';
                        this.signUpForm.reset();
                        // this.phoneNumberComp.phoneInput.a.value = '';
                        this.submitted = false;
                        this.errorlog = false;
                        console.log("tessstts");
                        localStorage.setItem("isFbGmailLogin", "true")
                        this.messageService.serviceType.next(data.data.fullName)
                        this.doLoginData(data);
                    }else
                    {
                        this.spinner.show();
                        const phone = this.object.countryCode + this.object.phoneNo;
                        localStorage.setItem('phoneNo', phone)
                        localStorage.setItem("accessToken", data.data.accessToken);
                        localStorage.setItem("userData", JSON.stringify(data.data));
                        this.sendOTP();
                        this.messageService.googleSignInData.next(data.data);
                        // setTimeout(() => {
                            // this.spinner.hide();
                        // }, 3000);
                    }
                    
                },
                (error: { error: { message: string; statusCode: number } }) => {
                    console.log('error signup2', error);
                    this.spinner.hide();
                    this.errorlog = true;
                    this.errormsg = error.error.message;
                    return;
                }
            )
        }
        else {
            this.isContact = true;
            this.spinner.hide();
            return;
        }
    }

    togglePassword(item: string) {
        const el = document.getElementById(item);
        if (!this.showPassword) {
          el.setAttribute('type', 'text');
          this.showPassword = true;
        } else {
          el.setAttribute('type', 'password');
          this.showPassword = false;
        }
      }

      redirectToRegister(){
        this.router.navigate(['/register']);
      }
    
      redirectTologin(){
        this.router.navigate(['/login'])
      }
}






