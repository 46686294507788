import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModule } from './modules/bs.module';
import { PrimngModule } from './modules/primng.module';

import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActiveOnClickDirective } from './directives/on-click-active.directive';
import { PhoneNumberSignupComponent } from './components/phone-number-signup/phone-number-signup.component';
//import { LoginService } from '../account/login';

@NgModule({
    declarations: [
        PhoneNumberSignupComponent,
        PhoneNumberComponent,
        ActiveOnClickDirective,
        
    ],
    imports: [
        CommonModule,
        BsModule,
        PrimngModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        PhoneNumberComponent,
        PhoneNumberSignupComponent,
        ActiveOnClickDirective,
    ],
    providers: [],

})
export class SharedByAppModule {
    constructor() {
        // console.log('shared called !');
    }
}