import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingHistoryComponent } from './booking-history';
import { Routes, RouterModule } from '@angular/router';
import { BookingRoutingModule } from './booking-history.routing';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';





@NgModule({
  declarations: [BookingHistoryComponent],
  imports: [
    CommonModule,
    HttpClientModule ,
    BookingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [
    BookingHistoryComponent
],
})
export class BookingHistoryModule {
  constructor() {
    // console.log('booking module called !');
}
 }
