import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing';
import { SharedByAppModule } from '../shared-by-app/shared-module';
import { PrimngModule } from '../shared-by-app/modules/primng.module';
import { CarouselModule } from 'primeng/carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        SharedByAppModule,
        CarouselModule,
        FormsModule, 
        ReactiveFormsModule ,
    ],
    providers: [],

})
export class HomeModule {
    constructor() {
        // localStorage.removeItem("isBookAgain");
        // localStorage.removeItem("maidDetails")
        // console.log('home called !');
    }
}