import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  SafetyGuardsComponent } from './safety-guards.component';
import {SafetyGuardsRoutingModule } from './safety-guards.routing';



@NgModule({
  declarations: [SafetyGuardsComponent],
  imports: [
    CommonModule,
    SafetyGuardsRoutingModule
  ]
})
export class SafetyGuardsModule { }
