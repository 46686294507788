import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings';
import { SettingsRoutingModule } from './settings.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedByAppModule } from '../shared-by-app/shared-module';
import { BsModule } from '../shared-by-app/modules/bs.module';



@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedByAppModule,
    BsModule
  ]
})
export class SettingsModule { }
