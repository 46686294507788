import { Component, ViewChild, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { ForgotModel } from './forgot.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navrbar.service';
import { ForgotService } from './forgot.service';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from '../login/login.service';
import { AccountService } from 'src/app/services/account/account.service';
declare var $: any;
@Component({
    selector: "forgot-ui",
    templateUrl: "./forgot.html",
    styleUrls: ['./forgot.css', './forgot.less'],
    encapsulation: ViewEncapsulation.None
})
export class ForgotComponent implements OnInit, OnDestroy {
    @Input() id: string;
    forgotForm: FormGroup;
    submitted = false;
    errorlog = false;
    errormsg: string = '';
    private element: any;
    obj: {};

    object: any;
    isVisibleSignUpPopUp: boolean;
    isClickBackGround: boolean;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private navbarSvc: NavbarService,
        private modalService: ForgotService,
        private el: ElementRef,
        public commonService: CommonService,
        private spinner: NgxSpinnerService,
        private loginService: LoginService,
        private accountSvc: AccountService,

        // private modal: NgbModal
    ) {
        this.navbarSvc.showSimpleNavbar();
        this.navbarSvc.showFooter();
        this.element = el.nativeElement;
    }
    ngOnInit(): void {
        this.isVisibleSignUpPopUp = false;
        this.initializeSignUpForm();

        if (!this.id) {
            console.error('modal must have an id');
            return;
        }



        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'login-ui' || this.isVisibleSignUpPopUp == true) {
                this.close();
            }
        })

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }





    initializeSignUpForm() {
        this.forgotForm= this.formBuilder.group({
            'email': new FormControl('', [Validators.required, Validators.email]),
        });
    }

    get f() { return this.forgotForm.controls; }

    onSubmitForgotForm() {
        this.isVisibleSignUpPopUp = false;
        this.submitted = true;
        if (this.forgotForm.invalid) {
            return;
        }
        this.spinner.show();
            this.obj = {
                "email": this.forgotForm.controls.email.value,
                "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220"
            }
            this.accountSvc.forgotPassword(this.obj).subscribe((response) => {
                this.isVisibleSignUpPopUp = true;
                
                // document.body.classList.remove('login-ui-open');
                this.spinner.hide();
                this.forgotForm.reset();
                $("#success_modal").modal('show');      
               
            }, (error: { error: { message: string; statusCode: number }; }) => {
                this.spinner.hide();
                this.errorlog = true;
                this.errormsg = error.error.message;
                setTimeout(()=> {
                    this.errorlog = false;
                  }, 3000);
                return;
            })

        
    }


    onCloseModal() {
        this.isClickBackGround=false;
        this.element.style.display = 'none';
        // this.openLoginModal('custom-modal-12');
        this.router.navigateByUrl('/login')
       
    }


   

    ngOnDestroy() {

    }

    // open modal
    open(): void {
        this.isClickBackGround=true;
        this.forgotForm.reset();
        this.submitted = false;
        this.isVisibleSignUpPopUp = false;
        this.element.style.display = 'block';
        document.body.classList.add('login-ui-open');
    }

    // close modal
    close(): void {
        this.isClickBackGround=false;
        this.forgotForm.reset();
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
        
    }

    openLoginModal(id: string) {
        this.close();
        this.loginService.open(id);
    }

    closeLoginModal(id: string) {
        this.loginService.close(id);
    }
    

    // closeForgotModal(id: string){
    //     this.close();
    //     this.forgotService.close(id);
    // }

    redirectToLogin(){
        // this.close()
        // this.modalService.close(id);
        // console.log(" eNtter in redirect login")
        // window.location.reload()
        this.router.navigate(['/login'])
        
    }
   
}






