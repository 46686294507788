import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MyFavouritesComponent } from './my-favourites';
import { MyFavouritesListingComponent } from './my-favourites-listing/my-favourites-listing.component';

export const routes: Routes = [
    { path: '', component: MyFavouritesComponent, data: { title: "My Favourites  | MAK.today" } },
    { path: 'my-favourites-detail/:id', component: MyFavouritesListingComponent, data: { title: "My Favourites  | MAK.today" } },

    // { path: '', component: BookingHistoryComponent  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyfavouritesRoutingModule { 
    constructor() {
        // console.log('booking routing called !');
    }
}