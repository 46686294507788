
import { BrowserModule } from '@angular/platform-browser';
import { NgModule , ViewChild, ElementRef, NgZone} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentModule } from '../shared-by-app/components/common-components/common-component.module';
import { AuthInterceptor } from '../services/auth-services/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModule } from '../shared-by-app/modules/bs.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { DataShareService } from '../services/datashareservice';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { LoginModule } from '../account/login';
import { ModalModule } from '../_modal';
import { NgxSpinnerModule } from "ngx-spinner";
import { MessageService } from 'primeng/api';
import { CustomMessageService } from '../services/custom-message.service';
import {BookingHistoryModule} from "../booking-history/booking-history.module"
import { HomeModule } from '../home/home.module';
import { MyFavouritesModule } from '../my-favourites/my-favourites.module';
import { SettingsModule } from '../settings/settings.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { SignupModule } from '../account/signup/signup.module';
import { ForgotModule } from '../account/forgot/forget.module';
import { CurrentDayBookingRecordModule } from '../current-booking/current-booking.module';
import { CurrentMaidBookingRecordModule } from '../current-maid-booking-records/current-maid-booking.module';
import { DisinfectionModule } from '../static-pages/disinfection/disinfection.module';
import { MaidsTermsAndConditionsModule } from '../static-pages/maid-term/maid-term.module';
import { MaidPrivacyPolicyModule } from '../static-pages/maid-privacy/maid-privacy.module';
import { SafetyGuardsModule } from '../static-pages/safety-gurads/safety-guards.module';
// Import the library
import { environment } from 'src/environments/environment';
import { OtpModule } from '../account/otp';
import { PhoneModule } from '../account/phone';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonComponentModule,
    BrowserAnimationsModule,
    BsModule,
    HomeModule,
    LoginModule,
    FormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    // NgbModule,
    // RatingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlacesKey, 
      libraries: ['places']
    }),
    BookingHistoryModule,
    MyFavouritesModule,
    SettingsModule,
    CurrentDayBookingRecordModule,
    SignupModule,
    ForgotModule,
    OtpModule,
    PhoneModule,
    CurrentMaidBookingRecordModule,
    DisinfectionModule,
    MaidsTermsAndConditionsModule,
    MaidPrivacyPolicyModule,
    ModalModule,
    SafetyGuardsModule,
    SimpleNotificationsModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,},
    DataShareService,
  LoginModule,
  MessageService,
  CustomMessageService,
	ModalModule,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
