import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { CurrentBookingRoutingModule } from './current-booking.routing';
import { currentDayBookingComponent } from './current-booking';





@NgModule({
  declarations: [currentDayBookingComponent],
  imports: [
    CommonModule,
    HttpClientModule ,
    CurrentBookingRoutingModule ,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [
    currentDayBookingComponent
],
})
export class CurrentDayBookingRecordModule {
  constructor() {
    // console.log('booking module called !');
}
 }
