import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { AllEndPoints } from '../shared-models/end-points';
import 'rxjs/add/operator/map';
// import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BookingService {
    public noHeaders: HttpHeaders;
    public Headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.noHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Header': 'True' });
        this.Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.Headers = new HttpHeaders();
        
        if(localStorage.getItem('accessToken')){
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
    }
}

   listOfAllBookingDetail(data) {
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url =  AllEndPoints.Booking.listOfAllBookingServiceUser;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    currentDaysRecord(data) {
        // let params = new HttpParams()
        // Object.keys(data).forEach(function (item) {  
        //     params = params.set(item, data[item]);
        // });
        // const url =  AllEndPoints.Booking.currentDayRecords;
        // console.log(url,"url")
        // return this.http.get<any>("https://mak.today/user/dayWiseBookingSms?userId=5c8f8cda098a7e0ed41d7a03&type=agency")
        return this.http.get<any>("http://35.160.43.15:8004/user/dayWiseBookingSms?userId=5c8f8cda098a7e0ed41d7a03&type=agency")
        // return this.http.get("http://35.160.43.15:8004/user/dayWiseBookingSms?userId=5c8f8cda098a7e0ed41d7a03&type=agency").map(res => res)
    }

    // getHistory(user) {
    //     const url =  AllEndPoints.Booking.currentDayRecords;
    //     return this.http.get<any>(url + '?user_id=' + user.user_id + '&startDate=' + user.startDate + '&endDate=' + user.endDate + '&access_token=' + user.access_token)
    //       .pipe(catchError(this.errorHandler))
    //   }

    cancelBooking(data) {
        const url = AllEndPoints.Booking.cancelOfBooking;
        return this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
    }

    // currentDayRecords(data) {
    //     let params = new HttpParams()
    //     Object.keys(data).forEach(function (item) {  
    //         params = params.set(item, data[item]);
    //     });
    //     const url = AllEndPoints.Booking.currentDayRecords;
    //     return this.http.get(url, {params:params}).map(res => res)
    //     // return this.http.get(url, {params:data}).map(res => res)
    // }

    ongoingExtension(data) {
        const url = AllEndPoints.Booking.extend;
        return this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
    }

}