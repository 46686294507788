import { Component, ViewEncapsulation, AfterViewInit, Input, ElementRef, Renderer2 } from '@angular/core';
import 'intl-tel-input';
declare var intlTelInput: any;
@Component({
    selector: 'phone-number-signup',
    templateUrl: './phone-number-signup.html',
    styleUrls: ['./phone-number-signup.css'],

})
export class PhoneNumberSignupComponent implements AfterViewInit {

    public phoneInput: any;
    public isPhoneNumberInvalid: boolean;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) {
        setTimeout(() => {
            let el = this.element.nativeElement;
            this.renderer.setStyle(el.childNodes[0], 'width', '100%');
        }, 50);
    }

    ngOnInit() {
        this.initializeInputTel();
    }
    initializeInputTel() {
        var input = document.querySelector("#phone1");
        this.phoneInput = intlTelInput(input, {
            initialCountry: "bh"
        });
    }

    @Input('isPhoneNumberInvalid')
    set getState(state: boolean) {
        if (state) {
            this.isPhoneNumberInvalid = state;
        }
    }

    checkPhoneNumber() {
        // var valid = this.phoneInput.isValidNumber();
        var valid = this.phoneInput;
        if (valid) {
            this.isPhoneNumberInvalid = false;
        }
        else {
            this.isPhoneNumberInvalid = true;
        }
    }


    ngAfterViewInit() {

    }

}
