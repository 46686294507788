import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import 'rxjs/add/operator/do';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    public isUnauthorize: boolean;
    constructor(private router: Router) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Header') == "True") {
            req = req.clone({ headers: req.headers.delete('No-Header', 'True') })
            return next.handle(req.clone());
        }

        console.log(localStorage.getItem('accessToken') , " ACCES TOKEN ")
        if (localStorage.getItem('accessToken') != null) {
            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('accessToken'))
            });
            return next.handle(clonedreq)
                .do(
                    succ => {
                    },
                    err => {
                        if (err.status === 401) {
                            if (!this.isUnauthorize) {
                                this.isUnauthorize = true;
                            }
                            
                            localStorage.clear();                            
                            this.router.navigateByUrl('/');
                        }
                    }
                );
        }
        else {
            // debugger
            localStorage.clear();
            this.router.navigateByUrl('/');
        }
    }
}