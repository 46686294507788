import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


export const routes: Routes = [
  // { path: '', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('../account/new-login/new-login.module').then(m => m.NewLoginModule) },
  { path: 'login', loadChildren: () => import('../account/new-login/new-login.module').then(m => m.NewLoginModule) },
  // { path: 'register', loadChildren: () => import('../account/signup/signup.module').then(m => m.SignupModule) },
  { path: 'forgot', loadChildren: () => import('../account/forgot/forget.module').then(m => m.ForgotModule) },
  { path: 'register', loadChildren: () => import('../account/new-sign-up/new-sign-up.module').then(m => m.NewSignupModule) },
  
  //  { path: '', loadChildren: () => import('../account/login/login.module').then(m => m.LoginModule) },
  { path: 'current-day-records/:id/:id1', loadChildren: () => import('../current-booking/current-booking.module').then(m => m.CurrentDayBookingRecordModule) },
  { path: 'current-day-records', loadChildren: () => import('../current-booking/current-booking.module').then(m => m.CurrentDayBookingRecordModule) },
  { path: 'current-day-records-maid', loadChildren: () => import('../current-maid-booking-records/current-maid-booking.module').then(m => m.CurrentMaidBookingRecordModule) },
  { path: 'userVerification/:id', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
  // { path: 'login', loadChildren: () => import('../account/login/login.module').then(m => m.LoginModule) },
  // { path: 'signup', loadChildren: () => import('../account/signup/signup.module').then(m => m.SignupModule) },
  {
    path:'otp', loadChildren: ()=>import('../account/otp/otp.module').then(m => m.OtpModule)
  },
  {
    path:'phone-number', loadChildren: ()=>import('../account/phone/phone.module').then(m => m.PhoneModule)
  },

  { path: 'housemaid-cleaner-jobs-bahrain', loadChildren: () => import('../account/register/register.module').then(m => m.RegisterModule) },
  { path: 'FAQ', loadChildren: () => import('../static-pages/faq/faq.module').then(m => m.FaqModule) },
  
  { path: 'blogs', loadChildren: () => import('../static-pages/blog/blog.module').then(m => m.BlogModule) },
  { path: 'disinfection-sanitization-service-Bahrain', loadChildren: () => import('../static-pages/disinfection/disinfection.module').then(m => m.DisinfectionModule) },

  { path: 'blogs/5-Effective-ways-to-spend-less-time-cleaning', loadChildren: () => import('../static-pages/blog-first/blog-first.module').then(m => m.BlogFirstModule) },
  { path: 'blogs/Best-Hygiene-Practices-to-follow-during-the-Coronavirus-pandemic', loadChildren: () => import('../static-pages/blog-second/blog-second.module').then(m => m.BlogSecondModule) },
 
  
  { path: 'housemaid-services-bahrain', loadChildren: () => import('../static-pages/service/service.module').then(m => m.ServiceModule) },
  { path: 'house-cleaning-washing-services-bahrain', loadChildren: () => import('../static-pages/washing/washing.module').then(m => m.WashingModule) },
  { path: 'hire-nanny-babysitter-bahrain', loadChildren: () => import('../static-pages/baby-sitting/baby-sitting.module').then(m => m.BabySittingModule) },
  { path: 'maid-cleaning-services-companies-bahrain', loadChildren: () => import('../static-pages/cleaning/cleaning.module').then(m => m.CleaningModule) },
  { path: 'house-cook-bahrain', loadChildren: () => import('../static-pages/cooking/cooking.module').then(m => m.CookingModule) },
  { path: 'terms_and_conditions', loadChildren: () => import('../static-pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule) },
  { path: 'termAndConditions', loadChildren: () => import('../static-pages/terms-and-conditions-copy/terms-and-conditions-copy.module').then(m => m.TermsAndConditionsCopyModule) },
  { path: 'privacy_policy', loadChildren: () => import('../static-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'maid_privacyPolicy', loadChildren: () => import('../static-pages/maid-privacy/maid-privacy.module').then(m => m.MaidPrivacyPolicyModule) },
  { path: 'maid_termsOfService', loadChildren: () => import('../static-pages/maid-term/maid-term.module').then(m => m.MaidsTermsAndConditionsModule) },
  { path: 'makPrivacyPolicy', loadChildren: () => import('../static-pages/privacy-policy-copy/privacy-policy-copy.module').then(m => m.PrivacyPolicyCopyModule) },
  { path: 'safety', loadChildren: () => import('../static-pages/safety-gurads/safety-guards.module').then(m => m.SafetyGuardsModule) },

  { path: 'thankyu', loadChildren: () => import('../static-pages/thankyu/thankyu.module').then(m => m.ThankyuModule) },
  { path: 'payment', loadChildren: () => import('../static-pages/payment/payment.module').then(m => m.PaymentModule) },

  { path: 'hire-cleaner-in-London', loadChildren: () => import('../maid-booking/maid-booking.module').then(m => m.MaidBookingModule) },
  { path: 'booking-history-list', loadChildren: () => import('../booking-history/booking-history.module').then(m => m.BookingHistoryModule) },
  { path: 'my-favourites', loadChildren: () => import('../my-favourites/my-favourites.module').then(m => m.MyFavouritesModule) },
  { path: 'settings-menu', loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule) },

  { path: ':id', loadChildren: () => import('../current-maid-booking-records/current-maid-booking.module').then(m => m.CurrentMaidBookingRecordModule) },
  { path: '**', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
