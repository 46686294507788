import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhoneRoutingModule } from './phone-routing.module';
import { PhoneComponent } from './phone.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedByAppModule } from 'src/app/shared-by-app/shared-module';
import { BsModule } from 'src/app/shared-by-app/modules/bs.module';

@NgModule({
  declarations: [PhoneComponent],
  imports: [
    CommonModule,
    PhoneRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedByAppModule,
    BsModule
  ],
  exports: [PhoneComponent],
})
export class PhoneModule { }
