import { Component , ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { MyFavouritesService } from '../services/my-favourites.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment-timezone';
import { SettingsService } from '../services/settings.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MustMatch } from './settings.validators';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { CommonService } from '../services/common.service';
import { MessageService } from '../services/message.service';
import { PhoneNumberComponent } from '../shared-by-app/components/phone-number/phone-number.component';
import 'intl-tel-input';
declare var intlTelInput: any;
// import { MustMatch } from './validator';

declare var $: any;
@Component({
    selector: "settings",
    templateUrl: "./settings.html",
    styleUrls: ['./settings.css',], 
    encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit {
  @ViewChild(PhoneNumberComponent, { static: false }) phoneNumberComp: PhoneNumberComponent;
    obj: {};
    listOfFavouritesMaid: any;
    isData: boolean;
    isBookingType: any;
    userDetails: any;
    ashish:any;
    profileForm: FormGroup;
    resetForm:FormGroup;
    customFullName:any;
    submitted: boolean;
    sighUpUserData:any;
    changeType: boolean = true;
    visible: boolean = true;
    changeType2: boolean = true;
    visible2: boolean = true;
    changeType3: boolean = true;
    visible3: boolean = true;
    email:AbstractControl;
    // full_name:AbstractControl;
    first_name:AbstractControl;
    last_name:AbstractControl;
    phone_number:AbstractControl;
    object: any;
    errorlog_book: boolean;
    errormsg_book: string;
    passwordErrorLog: boolean;
    passwordErrorMsg: any;
    profileAndChangePasswordMsg: string;
    isFbGmailLogin: string;
    public phoneInput: any;
    public isPhoneNumberInvalid: boolean;
 
    constructor(
public settingsService : SettingsService ,
private spinner: NgxSpinnerService, // private modal: NgbModal
private formBuilder: FormBuilder, 
private router: Router,
private activatedRoute: ActivatedRoute,
public commonService:CommonService,
public messageService:MessageService
    ) {
      console.log("ts called")
      this.getLocalTimezone()
    } 
    ngOnInit(): void {
      
      if(localStorage.getItem('signUserData') == 'true')
      {
        this.sighUpUserData = JSON.parse(localStorage.getItem('userData'))
        console.log(this.sighUpUserData)
        if(this.sighUpUserData.firstName && this.sighUpUserData.lastName)
        {
          
          const [firstName, lastName] = this.sighUpUserData.fullName.split(' ');
            this.sighUpUserData.firstName = firstName
            this.sighUpUserData.lastName = lastName
        }
        if(this.sighUpUserData != null || this.sighUpUserData!= undefined)
        {
          this.initProfileForm2();
        }
       
      }
      else
      {
        this.initProfileForm();
        this.initLocalstorage();
      }
     this.initPasswordForm();
        }

        initializeInputTel(countryEnCode: string) {
          var input = document.querySelector("#phone_number");
          console.log("Ashish")
          this.phoneInput = intlTelInput(input, {
              initialCountry: countryEnCode,
          });
      }

      checkPhoneNumber() {
        // var valid = this.phoneInput.isValidNumber();
        var valid = this.phoneInput;
        if (valid) {
            this.isPhoneNumberInvalid = false;
        }
        else {
            this.isPhoneNumberInvalid = true;
        }
    }

        private getLocalTimezone(): string {
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          return timezone;
        }
        initProfileForm(){
            this.email = new FormControl('', [Validators.required, Validators.email]);
            // this.full_name = new FormControl('', [Validators.required]);
            this.first_name = new FormControl('', [Validators.required,Validators.maxLength(50)]);
            this.last_name = new FormControl('', [Validators.required,Validators.maxLength(50)]);
            this.phone_number = new FormControl('', [Validators.required]);
            this.profileForm = this.formBuilder.group({
              email: this.email,
            //   full_name: this.full_name,
              first_name: this.first_name,
              last_name: this.last_name,
              phone_number: this.phone_number,
            });
        }
        initProfileForm2(){
          this.email = new FormControl(this.sighUpUserData.email);
          this.first_name = new FormControl(this.sighUpUserData.firstName, [Validators.required]);
          this.last_name = new FormControl(this.sighUpUserData.lastName, [Validators.required]);
          this.phone_number = new FormControl(this.sighUpUserData.phoneNo, [Validators.required]);
          this.profileForm = this.formBuilder.group({
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            phone_number: this.phone_number,
          });
          
          console.log("ytgytygytvytv" +this.profileForm.controls.first_name.value)
          console.log("ytgytygytvytv" +this.profileForm.controls.email.value)
          console.log("ytgytygytvytv" +this.profileForm.controls.last_name.value)
          console.log("ytgytygytvytv" +this.profileForm.controls.phone_number.value)
          this.initializeInputTel(this.sighUpUserData.countryENCode.toLowerCase());

      }

        initPasswordForm(){
            this.resetForm = this.formBuilder.group({
                password: ['', [Validators.required]],
                confirm_password: ['', Validators.required],
                new_password: ['', Validators.required]
            },
            // {
            //     validator: MustMatch('new_password', 'confirm_password')
            // }
            )
         console.log("-----------------------------------")
        }

        
        initLocalstorage(){
            this.isFbGmailLogin = localStorage.getItem('isFbGmailLogin')
           
           this.userDetails= JSON.parse(localStorage.getItem('userData'))
           let profileUpdate = JSON.parse(localStorage.getItem('profileUpdate'))
          this.obj = {
        "buildingName": this.userDetails.usersAddress.buildingName,
  "city": this.userDetails.usersAddress.city,
  "country": this.userDetails.usersAddress.country,
  "countryCode": this.userDetails.countryCode,
  "countryENCode": this.userDetails.countryENCode,
  "email": this.userDetails.email,
//   "moreDetailedaddress": "656",
//   "optForEmail": false,
  "streetName": this.userDetails.usersAddress.streetName,
  "timeZone": this.userDetails.timeZone,
  "uniquieAppKey": this.userDetails.uniquieAppKey,
  "villaName": ""
        }
        console.log('eountryencode', profileUpdate ? profileUpdate.countryENCode : this.userDetails.countryENCode.toLowerCase())
        this.initializeInputTel(profileUpdate ? profileUpdate.countryENCode : this.userDetails.countryENCode.toLowerCase());
    this.setProfileValue();
        }

        setProfileValue(){
            this.phone_number.setValue( (JSON.parse(localStorage.getItem('profileUpdate')))?(JSON.parse(localStorage.getItem('profileUpdate'))).phoneNo:this.userDetails.phoneNo)
            this.ashish=( (JSON.parse(localStorage.getItem('profileUpdate')))?(JSON.parse(localStorage.getItem('profileUpdate'))).phoneNo:this.userDetails.phoneNo)
            console.log(this.ashish)
            this.email.setValue( this.userDetails.email)
            // this.full_name.setValue((JSON.parse(localStorage.getItem('profileUpdate')))?(JSON.parse(localStorage.getItem('profileUpdate'))).fullName:this.userDetails.fullName)
            console.log((JSON.parse(localStorage.getItem('profileUpdate')))?(JSON.parse(localStorage.getItem('profileUpdate'))).fullName:this.userDetails.fullName)
            this.customFullName=(JSON.parse(localStorage.getItem('profileUpdate')))?(JSON.parse(localStorage.getItem('profileUpdate'))).fullName:this.userDetails.fullName
            const [firstName, lastName] = this.customFullName.split(' ');
            console.log(firstName)
            console.log(lastName)
            this.first_name.setValue(firstName)
            this.last_name.setValue(lastName)
            console.log(this.email.setValue( this.userDetails.email),this.email,this.userDetails.email)
        }

        onUpdateProfile(){
            this.submitted = true;
            // this.commonService.setLoader(false)
            if (this.profileForm.invalid) {
              return;
            }
            if(this.phoneInput.a.value != '')
            {
              // debugger;
              // this.obj['phoneNo']=this.phoneInput.a.value;
              this.obj['countryENCode']=(this.phoneInput.s.iso2).toUpperCase();
              this.obj['countryCode']='+' + this.phoneInput.s.dialCode;
            }else{
              this.obj['phoneNo']=this.profileForm.controls.phone_number.value;
            }
            if(localStorage.getItem('signUserData') == 'true')
            {
              this.obj = {
                "fullName":this.profileForm.controls.first_name.value + " " + this.profileForm.controls.last_name.value,
                "firstName":this.profileForm.controls.first_name.value,
                "lastName":this.profileForm.controls.last_name.value,
                "phoneNo":this.profileForm.controls.phone_number.value,
                "email": this.profileForm.controls.email.value,
                "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220",
                "timeZone": this.getLocalTimezone(),
                "countryCode": this.sighUpUserData.countryCode,
                  "buildingName": '',
            "city": '',
            "country": '',
            "countryENCode":'US',
            "streetName":'',
            "villaName": ""  
              }
            }else
            {
              this.obj['fullName']=this.profileForm.controls.first_name.value + " " + this.profileForm.controls.last_name.value
              this.obj['firstName']=this.profileForm.controls.first_name.value
              this.obj['lastName']=this.profileForm.controls.last_name.value
              this.obj['phoneNo']=this.profileForm.controls.phone_number.value
            }
            // console.log(this.obj);
            // return false;
            window.scrollTo(0, 0);
            this.spinner.show()
            // console.log(this.settingsService.updateProfile(this.obj))
            this.settingsService.updateProfile(this.obj).subscribe(
              
                (data: any)  => {
                  // debugger
                    this.spinner.hide()
                    this.submitted = false;
                    localStorage.setItem('profileUpdate',JSON.stringify(data.data));
                    this.messageService.serviceType.next(data.data.fullName)   
                if(data.statusCode == 200){
                    $("#Profile_updte").modal('show');
                }
                this.setProfileValue();
    
                },
                (error: { error: { message: string;statusCode:number }; })  => {
                    this.commonService.authentication(error)
                    this.spinner.hide();
                //       this.errorlog_book= true ;
                //   this.errormsg_book = error.error.message ;
                      return;
                  }
            )
        }
        keyPressAlphanumeric1(event:any) {
          console.log(event.target.value)
          var val=event.target.value
          // var inp = String.fromCharCode(event.keyCode);
          // if (!/[0-9]/.test(inp)) {
          //   return true;
          // } else {
          //   event.preventDefault();
          //   this.alpha = true;
          //   setTimeout(() => {
          //     this.alpha = false;
          //   }, 4000)
          //   return false;
          // }
        }
        onChangePassword2(){
          console.log(this.resetForm.value)
        }
        viewpass() {
          this.visible = !this.visible;
          this.changeType = !this.changeType;
        }
        viewpass2() {
          this.visible2 = !this.visible2;
          this.changeType2 = !this.changeType2;
        }
        viewpass3() {
          this.visible3 = !this.visible3;
          this.changeType3 = !this.changeType3;
        }
        onChangePassword(){
          debugger
            // console.log(this.resetForm,"reset form")
            this.submitted = true;
            this.passwordErrorLog= false;
            // this.commonService.setLoader(false)
            if (this.resetForm.invalid) {
              return;
            }
            console.log(this.resetForm.value)
            this.profileAndChangePasswordMsg=""
            this.passwordErrorMsg =""
            debugger
            if(!this.resetForm.controls.password.value && !this.resetForm.invalid){
                this.passwordErrorLog= true ;
                this.passwordErrorMsg = "Password is required" ;
                setTimeout(()=>{
                  this.passwordErrorLog= false ;
                }, 5000);
                     return;
            }
            if(!this.resetForm.controls.new_password.value && !this.resetForm.invalid){
                this.passwordErrorLog= true ;
                this.passwordErrorMsg = "New password is required" ;
                setTimeout(()=>{
                  this.passwordErrorLog= false ;
                }, 5000);
                     return;
            }
            if(!this.resetForm.controls.confirm_password.value && !this.resetForm.invalid){
                this.passwordErrorLog= true ;
                this.passwordErrorMsg = "Confirm password is required" ;
                setTimeout(()=>{
                  this.passwordErrorLog= false ;
                }, 5000);
                     return;
            }
            if(this.resetForm.controls.new_password.value == this.resetForm.controls.password.value){
                this.passwordErrorLog= true ;
                this.passwordErrorMsg = "New password should not be the same as the old password" ;
                setTimeout(()=>{
                  this.passwordErrorLog= false ;
                }, 5000);
                     return;
            }
            if(this.resetForm.controls.new_password.value !== this.resetForm.controls.confirm_password.value){
                this.passwordErrorLog= true ;
                this.passwordErrorMsg = "New password and Confirm password must be same" ;
                setTimeout(()=>{
                  this.passwordErrorLog= false ;
                }, 5000);
                     return;
            }
            // console.log(this.resetForm.controls.password.value,this.resetForm.controls.new_password.value,"fjsdgjdfsjg")
            this.object={
                'oldPassword':this.resetForm.controls.password.value,
                 'newPassword':this.resetForm.controls.new_password.value,
            // this.object['confirm_password']=this.profileForm.controls.phone_number.value
                  'uniquieAppKey':"892d9a5e3c21f60eb95ad196da1b0220"
            }

              window.scrollTo(0, 0);
              this.spinner.show();
              this.settingsService.changePassword(this.object).subscribe(
                  (data: any)  => {
                      this.spinner.hide()
                      this.submitted = false;
                      $("#password_update").modal('show');
                   
                    //   console.log(data.data,"data in all view")
                  },
                  (error: { error: { message: string;statusCode:number }; })  => {
                  
                      this.spinner.hide();
                      this.submitted = false;
                      this.commonService.authentication(error)
                      this.errorlog_book= true ;
                   this.errormsg_book = error.error.message ;
                   setTimeout(()=>{
                    this.errorlog_book= false ;
                  }, 5000);
                        return;
                    }
              )
        }
        onPasswordChange(){
            this.router.navigateByUrl('');
        }

        redirectToHome(){
            this.router.navigateByUrl('/hire-cleaner-in-London');
        }

         // Validation function

  get f() { return this.profileForm.controls; }
  get g() { return this.resetForm.controls; }
  onSubmit() {
    this.submitted = true;
    // this.commonService.setLoader(false)
    if (this.resetForm.invalid) {
      return;
    }
  }
     
//   on tab change
onTabChange(){
    this.submitted=false;
}

deleteAccount() {
  const object = {
    'uniquieAppKey':"892d9a5e3c21f60eb95ad196da1b0220",
    'action': "DELETE"
  }
  this.settingsService.deleteAccount(object).subscribe((data: any) => {
    console.log(data);
    if(data.statusCode == 200) {
      this.hideConfirmModal();
      $('#delete_account_success').modal('show');
    }
  })
}

hideConfirmModal() {
  $('#delete_account').modal('hide');
}

hideDeleteSuccessModal() {
  $('#delete_account_success').modal('hide');
  localStorage.clear();
  this.router.navigateByUrl("/register");

}
}