//import * as currentJson from '../../assets/static-json-settings/prod.json';
import * as currentJson from '../../assets/static-json-settings/current.json';
var currentJsonRec = currentJson.default;
import { environment } from '../../environments/environment';
const BaseUrl = environment.apiEndPointUrl;
const uniqueKeyForQs = environment.uniqueKey;


export const AllEndPoints = {

    uniqueAppKey: {
        get uniqueKey() {
            return currentJsonRec.uniqueKey;
        }
    },

    Accounts: {
        get loginUser() {
            return BaseUrl + 'user/emailLogin';
        },
        get loginUserWithGoogle() {
            return BaseUrl + 'user/facebookLogin';
        },
        get loginUserWithGoogleCheck() {
            return BaseUrl + 'user/signUp2';
        },
        get contactUs() {
            return BaseUrl + 'agency/contactUs';
        },
        get mailVerification() {
            return BaseUrl + 'user/verifyUser';
        },
        get signUpUser() {
            return BaseUrl + 'user/signUp';
        },
        get forgotPassword() {
            return BaseUrl + 'user/forgetPassword';
        },      
        get verifyOTP() {
            return BaseUrl + 'user/verifyOTP';
        },
        
        get resendOTP() {
            return BaseUrl + 'user/resendOTP';
        },

        getAgencies(uniqueKey: string) {
            return BaseUrl + 'maid/listMakAgencyForMaid?uniquieAppKey=' + uniqueKey;
        },
        get addMaidDetail() {
            return BaseUrl + 'maid/maidSignup1';
        },

        getBuildings(uniqueKey: string) {
            return BaseUrl + 'user/listAllBuilding?uniquieAppKey='+uniqueKey+'&limit=20&pageNo=1';
        },
        get signUpUserByBuilding() {
            return BaseUrl + 'user/signUpByBuilding';
        },
        get emailLoginByBuilding() {
            return BaseUrl + 'user/emailLoginByBuilding';
        },
    },
    Booking: {
        get listOfAllBookingServiceUser() {
            return BaseUrl + 'user/listAllServiceUser';
        },
        get currentDayRecords() {
            return BaseUrl + 'user/dayWiseBookingSms';
        },
        get currentDayMaidsRecords() {
            return BaseUrl + 'user/dayWiseBookingSmsAgency';
        },
        get cancelOfBooking() {
            return BaseUrl + 'user/cancelService';
        },
        get extend() {
            return BaseUrl + 'user/requestForExtendService';
        },
    },
    MyFavourites: {
        get listOfAllFavouritesMaidService() {
            return BaseUrl + 'user/listFavouriteMaid';
        },
        get addFavouritesMaid() {
            return BaseUrl + 'user/addFavouriteMaid';
        },
        get removeFavouritesMaid() {
            return BaseUrl + 'user/removeFavouriteMaid';
        },
       
    },
    Settings: {
        get updateProfile() {
            return BaseUrl + 'user/updateUserProfile';
        },
        get changePassword() {
            return BaseUrl + 'user/changePassword';
        },
        get deleteAccount() {
            return BaseUrl + 'user/deleteUser';
        }
    },
    Users: {
        get saveAddress() {
            return BaseUrl + 'user/saveAddress';
        },
        get deleteAddress() {
            return BaseUrl + 'user/deleteAddress';
        }
    },
    MaidBooking: {
        get getMaidBookingServices() {
            return BaseUrl + 'admin/MaidServiceList?uniquieAppKey=' + uniqueKeyForQs;
        },
        get getCards() {
            return BaseUrl + 'user/getCards?uniquieAppKey=' + uniqueKeyForQs;
        },        
        get deleteCard() {
            return BaseUrl + 'user/deleteCard';
        },        
        get addCard() {
            return BaseUrl + 'user/addCard';
        },

        get getMaidlisting() {
            return BaseUrl + 'user/listAllMaidsUser';
        },
        get getAllNationalitylisting() {
            return BaseUrl + 'user/listAllNationalityUser';
        },
        get getAllLanglisting() {
            return BaseUrl + 'user/listAllLanguagesUser';
        },
        get getAllReligionlisting() {
            return BaseUrl + 'user/listAllReligion';
        },
        get getAllAgencylisting() {
            return BaseUrl + 'user/listAllAgency';
        },
      
     
        get getMaidDetail() {
            return BaseUrl + 'user/getMaidProfileDetail';
        },
        get getBookAgain() {
            return BaseUrl + 'user/bookServiceAgain';
        },
        get getcheckMaidAvailable() {
            return BaseUrl + 'user/checkMaidAvailable';
        },
        get getBulkBookAgain() {
            return BaseUrl + 'user/searchBulkMaidAgain';
        },
        get getListBulkMaids() {
            return BaseUrl + 'user/listBulkMaidsUser';
        },
        get searchBulkMaid() {
            return BaseUrl + 'user/searchBulkMaidAgain?';
        },
        get bookMaidDetail() {
            return BaseUrl + 'user/bookService';
        },
        get promoCode() {
            return BaseUrl + 'user/applyPromoCode';
        },
        get createPayment() {
            return BaseUrl + 'user/createPayment';
        },
        get bookMaidpay() {
            return BaseUrl + 'user/createPaymentPaytabs';
        },
        get listOfBulkMaidUser() {
            return BaseUrl + 'user/listBulkMaidsUser';
        },
        get bookBulkMaidUser() {
            return BaseUrl + 'user/bookServiceBulk';
        },
        get bulkBookMaidpay() {
            return BaseUrl + 'user/createPaymentPaytabsBulk';
        },
        get chargeTap() {
            return BaseUrl + 'user/tapCreateCharge';
        },
        get tapChargeDetail(){
            return BaseUrl + 'user/tapChargeDetail';
        },
        get bookFailedpay() {
            return BaseUrl + 'user/testMail';
        },
        get getServicelisting() {
            return BaseUrl + 'admin/listService?uniquieAppKey=' + uniqueKeyForQs+'&limit=100&pageNo=1';
        },
        get getPropertylisting(){
            return BaseUrl + 'admin/listProperty?uniquieAppKey=' + uniqueKeyForQs+'&limit=100&pageNo=1';
        },
        get getPropertylistingDetail(){
            return BaseUrl + 'admin/listProperty?uniquieAppKey=' + uniqueKeyForQs+'&limit=100&pageNo=1';
        },

        // send email
        get sendEmail() {
            return BaseUrl + 'user/resendVerifyEmail?uniquieAppKey=' + uniqueKeyForQs;
        },
       

    },
    StartServices: {
        get getStartServices() {
            return BaseUrl + 'admin/getAllNormalService?uniquieAppKey=' + uniqueKeyForQs;
        }
    },


}