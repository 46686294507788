import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DisinfectionComponent } from './disinfection.component';

export const routes: Routes = [
    { path: '', component: DisinfectionComponent, data: { title: "Disinfection Sanitization Service in Bahrain" } },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DisinfectionRoutingModule { }