import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        BsDropdownModule.forRoot(),
	 
    ],
    exports: [
    ],
    providers: [],

})
export class BsModule {
    constructor() {
        // console.log('bs module called !');
    }
}