import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { LoginModel } from './login.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountService } from 'src/app/services/account/account.service';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navrbar.service';
import { LoginService } from './login.service';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import { SignupService } from '../signup';
import { MessageService } from '../../services/message.service';
import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider,
    LinkedinLoginProvider,
    SocialUser
} from 'ng4-social-login';
import { ForgotService } from '../forgot/forgot.service';
import { PhoneService } from "../phone";
import { OtpService } from "../otp/otp.service";
import { Location } from '@angular/common';
import { PhoneNumberComponent } from "src/app/shared-by-app/components/phone-number/phone-number.component";
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
declare var gtag
@Component({
    selector: "login-ui",
    templateUrl: "./login.html",
    styleUrls: ['./login.css', './login.less'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild(PhoneNumberComponent, { static: false }) phoneNumberComp: PhoneNumberComponent;
    loginForm: FormGroup;
    public loginObj: LoginModel = <LoginModel>{};
    submitted = false;
    errorLog:boolean = false;
    loginType:string="";
    errorMsg: string = '';
    changeType: boolean = true;
    visible: boolean = true;
    first_name2:string;
    last_name2:string;
    @Input() id: string;
    private element: any;
    private user: SocialUser;
    object: {};
    isClickBackGround: boolean;
    showPassword: boolean;
    isSocialLogin: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private accountSvc: AccountService,
        private router: Router,
        private navbarSvc: NavbarService,
        private modalService: LoginService,
        private el: ElementRef,
        public commonService: CommonService,
        private spinner: NgxSpinnerService,
        public signupService: SignupService,
        public forgotService:ForgotService,
        public phoneService:PhoneService,
        public messageService: MessageService,
        private authService: AuthService,
        public otpService:OtpService,
        private location: Location
        // private modal: NgbModal
    ) {
        this.navbarSvc.showSimpleNavbar();
        this.navbarSvc.showFooter();
        this.element = el.nativeElement;
    }

    ngOnInit(): void {

        console.log(" ENtER LOGIN PAGE")
            // Subscribe to the popstate event
        if(localStorage.getItem("accessToken") && JSON.parse(localStorage.getItem("userData")).profileComplete){
            this.navbarSvc.loginVar = true;
            this.router.navigateByUrl('/hire-cleaner-in-London');
            return
        }
           console.log(" OUTSIDE LOGIN ")   

        this.navbarSvc.loginVar = false;
        // localStorage.clear(); // nitin
        localStorage.removeItem("bookingTabType")
        // localStorage.setItem("bookingTabType", 'startService')
        this.initializeLoginForm();
        if (!this.id) {
            console.error('modal must have an id');
            // return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        // this.element.addEventListener('click', el => {
        //     if (el.target.className === 'login-ui') {
        //         this.close();
        //     }
        // });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
        // this.initGoogleFb();
     
    }
    
    initGoogleFb(){
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
        });
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
        });
    }
    openPhoneModal(id: string) {
        this.close();
        this.phoneService.open(id);
    }

    closeOTPModal(id: string) {
        this.phoneService.close(id);
    }
    signInWithGoogle(): void {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
            console.log("user " +this.user)
            if(this.user){
                console.log(this.user)
                this.loginType="GOOGLE";
                this.googleLogin(this.user);
            }
           
        });
    }
    signInWithFacebook(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
            this.user = userData;
            if(this.user){
                this.loginType="FACEBOOK"
                this.googleLogin(this.user);
            }
        });
    }
    initializeLoginForm() {
        this.loginForm = this.formBuilder.group({
            'email': new FormControl('', [Validators.required]),
            'password': new FormControl('', [Validators.required]),
        });
    }

    get f() { return this.loginForm.controls; }

    onSubmitLogin() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        else {
            this.spinner.show();
            this.loginObj.email = this.loginForm.get('email').value;
            this.loginObj.password = this.loginForm.get('password').value;

            this.accountSvc.loginUser(this.loginObj).subscribe(
                (data: any) => {
                    if(!data.data.isVerified)
                    {
                        console.log("inner verify")
                        //this.openPhoneModal('custom-modal-20')
                    }
                    this.spinner.hide();
                    // this.openPhoneModal('custom-modal-20')
                   
                    this.submitted = false;
                    this.errorLog = false;
                    this.loginForm.reset();
                    this.messageService.serviceType.next(data.data.fullName)
                    if(data.data.profileComplete){
                        this.doLoginData(data);
                     }else{
                        this.messageService.serviceType.next(data.data.email)
                        localStorage.setItem("accessToken", data.data.accessToken);
                        localStorage.setItem("userData", JSON.stringify(data.data));
                        localStorage.setItem("signUserData", 'false');
                        this.openPhoneModal('custom-modal-20')
                     }
                    
                   
                },
                (error: { error: { message: string; statusCode: number }; }) => {
                    this.spinner.hide();
                    this.errorMsg = error.error.message;
                    console.log(this.errorMsg)
                    console.log(error.error.statusCode, error.error.message, "error message")
                    this.commonService.authentication(error)
                    this.errorLog = true;
                    this.errorMsg = error.error.message;
                    console.log(this.errorMsg)
                    return;
                }
            )
        }
    }
    viewpass() {
        this.visible = !this.visible;
        this.changeType = !this.changeType;
      }

    doLoginData(response: any) {
        this.isClickBackGround=false;
       // this.spinner.show();
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("loginType", '1');
        localStorage.setItem("userData", JSON.stringify(response.data));
        // Google Analytics
        gtag('event', 'form_submit_login_success', {
            'event_category': 'form_submit',
            'event_label': 'Login Page',
            'value': 'form_submit_login_success'   })
        this.navbarSvc.loginVar = true;
        // this.openPhoneModal('custom-modal-20')
        //this.router.navigateByUrl('/hire-cleaner-in-London');

        
        if(response.data.isVerified){
            this.navbarSvc.loginVar = true;
            this.router.navigateByUrl('/hire-cleaner-in-London');
            this.spinner.hide();
        }
    }

    ngOnDestroy() {

    }

    // open modal
    open(): void {
        this.isClickBackGround=true;
        this.submitted = false;
        this.errorLog = false;
        this.loginForm.reset();
        this.errorMsg = '';
        this.element.style.display = 'block';
        document.body.classList.add('login-ui-open');
    }

    // close modal
    close(): void {
        this.isClickBackGround=false;
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
    }
    openOTPModal(id: string) {
        this.close();
        console.log("this.openOTPModal")
        this.otpService.open(id);
    }

    openSignUpModal(id: string) {
        this.close();
        this.signupService.open(id);

    }

    closeSignUpModal(id: string) {
        this.signupService.close(id);
    }

    openForgotModal(id: string){
        this.close();
        this.forgotService.open(id);
    }

    closeForgotModal(id: string){
        this.close();
        this.forgotService.close(id);
    }

    // login with gmail
    // googleLogin(userdata) {
    //     const [firstName, lastName] = userdata.name.split(' ');
    //     this.object = {
    //         'facebookId': userdata.id,
    //         'fullName': userdata.name,
    //         'firstName':firstName,
    //         'lastName':lastName,
    //         'email': userdata.email?userdata.email:"",
    //         'loginType' : this.loginType,
    //         // 'profilePicURL':null
    //     }
    //     console.log("========== googleLogin =====")
    //     console.log( this.object)
    //     this.spinner.show();
    //     this.accountSvc.loginUserWithGoogle(this.object).subscribe(
    //         (data: any) => {
    //             console.log(data.data.profileComplete)
    //             localStorage.setItem("accessToken", data.data.accessToken);
    //             localStorage.setItem("userData", JSON.stringify(data.data));
    //              if(data.data.profileComplete){
    //                 //  this.spinner.hide();
    //                 this.messageService.serviceType.next(data.data.fullName)
    //                localStorage.setItem("isFbGmailLogin","true")
    //                 this.doLoginData(data);
    //              }else{
    //                 //  this.spinner.hide();
    //                 //  this.messageService.googleSignInData.next(data.data)
    //                 //  this.openSignUpModal('custom-modal-13')

    //                 this.messageService.serviceType.next(data.data.fullName)
    //                 localStorage.setItem("isFbGmailLogin","true")
    //                 this.doLoginData(data);

    //                 //  if(!response.data.isVerified){
    //                     // this.openOTPModal('custom-modal-60')
    //                     // this.spinner.hide();
                   
    //              }
     
    //         },
    //         (error: { error: { message: string; statusCode: number }; }) => {
    //             this.spinner.hide();
    //             this.errorLog = true;
    //             this.errorMsg = error.error.message;
    //             return;
    //         }
    //     )
    // }

    googleLogin(userdata) {
        this.isSocialLogin = true;
        const [firstName, lastName] = userdata.name.split(' ');
        this.object = {
            'facebookId': userdata.id,
            'fullName': userdata.name,
            'firstName':firstName,
            'lastName':lastName,
            'email': userdata.email?userdata.email:"",
            'loginType' : this.loginType,
            // 'profilePicURL':null
        }
        // this.object = {
        //     'facebookId': "103766899",
        //     'fullName': "Anubhav",
        //     'firstName':"Anubhav",
        //     'lastName':"Singla",
        //     'email': "anubhav170.xperge@gmail.com",
        //     'loginType' : "GOOGLE",
        //     // 'profilePicURL':null
        // }
        console.log("========== googleLogin =====")
        console.log( this.object)
        this.spinner.show();
        this.accountSvc.loginUserWithGoogle(this.object).subscribe(
            (data: any) => {
                console.log(data.data.profileComplete)
                localStorage.setItem("accessToken", data.data.accessToken);
                localStorage.setItem("userData", JSON.stringify(data.data));
                 if(data.data.profileComplete){
                    //  this.spinner.hide();
                    this.messageService.serviceType.next(data.data.fullName)
                   localStorage.setItem("isFbGmailLogin","true")
                    this.doLoginData(data);
                 }else{
                    //  this.spinner.hide();
                    //  this.messageService.googleSignInData.next(data.data)
                    //  this.openSignUpModal('custom-modal-13')
                    console.log('sign in google login');
                    this.messageService.serviceType.next(data.data.fullName)
                    localStorage.setItem("isFbGmailLogin","true");

                    // Set isSocialLogin value in the shared service
                    this.messageService.setIsSocialLogin(this.isSocialLogin);

                    setTimeout(() => {
                        // Navigate to the signup page
                        this.router.navigate(['/register']);
                    }, 0);
                    

                    // this.doLoginData(data);

                    //  if(!response.data.isVerified){
                        // this.openOTPModal('custom-modal-60')
                        // this.spinner.hide();
                   
                 }
     
            },
            (error: { error: { message: string; statusCode: number }; }) => {
                this.spinner.hide();
                this.errorLog = true;
                this.errorMsg = error.error.message;
                return;
            }
        )
    }

    //Signup 2 API after Facebook or Google login/signup 
    googleLoginCheck() {
        this.object = {
            "buildingName": "",
            "city": "",
            "nationalId": "",
            "streetName": "",
            "villaName": "",
           "country": "",
          "documentPicURL": {},
            "countryCode": '+' + this.phoneNumberComp.phoneInput.s.dialCode,
            "countryENCode": this.phoneNumberComp.phoneInput.s.iso2.toUpperCase(),
            "deviceToken": "website",
            "deviceType": "WEB",
            "email": this.loginObj.email,
            "phoneNo": this.phoneNumberComp.phoneInput.a.value,
            // "timeZone": "Asia/Kolkata",
            "timeZone": this.getLocalTimezone(),
            
            "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220"
        }
        this.spinner.show();
        this.accountSvc.loginUserWithGoogleCheck(this.object).subscribe(
            (data: any) => {
                console.log('data signup2', data);
                console.log(data.data.profileComplete)
                if(data.data.profileComplete)
                {
                    // this.isVisiblePassword = false;
                    // this.errorlog = false;
                    // this.errormsg = '';
                    // this.signUpForm.reset();
                    this.phoneNumberComp.phoneInput.a.value = '';
                    this.submitted = false;
                    // this.errorlog = false;
                    console.log("tessstts");
                    localStorage.setItem("isFbGmailLogin", "true")
                    this.messageService.serviceType.next(data.data.fullName)
                    this.doLoginData(data);
                }else
                {
                    this.spinner.hide();
                    this.messageService.googleSignInData.next(data.data)
                    this.openOTPModal('custom-modal-60')
                }
                
            },
            (error: { error: { message: string; statusCode: number } }) => {
                console.log('error signup2', error);
                this.spinner.hide();
                // this.errorlog = true;
                // this.errormsg = error.error.message;
                return;
            }
        )
    }

    private getLocalTimezone(): string {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('timezone12', timezone);
        return timezone;
      }

  
    togglePassword(item: string) {
        const el = document.getElementById(item);
        if (!this.showPassword) {
          el.setAttribute('type', 'text');
          this.showPassword = true;
        } else {
          el.setAttribute('type', 'password');
          this.showPassword = false;
        }
      }

      trackMe() {
        console.log("trakc me called")
        // gtag('event', 'TRACK_ME_ACTION', {
        //   'form_submit_login_success': 'TRACK_ME_ACTION_EVENT_CATEGORY',
        //   'event_label': 'TRACK_ME_ACTION_EVENT_LABEL',
        //   'value': 'TRACK_ME_ACTION_EVENT_VALUE'   })

          gtag('event', 'form_submit_login_success', {
            'event_category': 'TRACK_ME_ACTION_EVENT_CATEGORY',
            'event_label': 'Login',
            'value': 'form_submit_login_success'   })

        

            // gtag('event', 'TRACK_ME_ACTION', {
            //     'event_category': 'TRACK_ME_ACTION_EVENT_CATEGORY',
            //     'event_label': 'TRACK_ME_ACTION_EVENT_LABEL',
            //     'value': 'TRACK_ME_ACTION_EVENT_VALUE'   })
            // }

            

      }


      redirectToRegister(){
        this.router.navigate(['/new-register']);
      }

      redirectToForgot(){
        this.router.navigate(['/forgot'])
      }
}