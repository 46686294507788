export const environment = {
  production: true,
  // "apiEndPointUrl": "http://35.160.43.15:9002/",
  // "uniqueKey": "892d9a5e3c21f60eb95ad196da1b0220",
  // "stripeKey":"pk_test_K3SjGaxO0LcLzBwoChaGIEkn",
  // "facebookID":"567816373639669",
  // "googleClientID":"800455808536-q8000la9e4i91kh63aaubiidbfne44fk.apps.googleusercontent.com",
  // "googlePlacesKey":"AIzaSyDs2o9fb4ac4x03IO0Etswq3mT9zMh7WR8",
  // "webBaseURL":"http://localhost:4200/"
  "apiEndPointUrl": "https://backend.mak.today/",
  "uniqueKey": "892d9a5e3c21f60eb95ad196da1b0220",
  "stripeKey":"pk_live_2FVsNAqaCovr6CSwU7GNRuHN",
  "facebookID":"567816373639669",
  "googleClientID":"800455808536-q8000la9e4i91kh63aaubiidbfne44fk.apps.googleusercontent.com",
  "googlePlacesKey":"AIzaSyDs2o9fb4ac4x03IO0Etswq3mT9zMh7WR8" ,
  "webBaseURL":"https://app.maktoday.co.uk/",
  "fireBaseConfig": {
    apiKey: "AIzaSyA-_vrZQ0Jt4ShK6U6JZ2dnXBbeCW3ZV7c",
    authDomain: "maktodayuserappbh-30a67.firebaseapp.com",
    databaseURL: "https://maktodayuserappbh-30a67.firebaseio.com",
    projectId: "maktodayuserappbh-30a67",
    storageBucket: "maktodayuserappbh-30a67.appspot.com",
    messagingSenderId: "464507319713",
    appId: "1:464507319713:web:e45b3899b1c0575c6a07e0",
    measurementId: "G-LE960S7ZFC"
  }
};
