import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { BookingService } from '../services/booking.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment-timezone';
import { CommonService } from '../services/common.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { BookingTabsUiDataModel, MaidServiceResponseModel, MaidServiceModel } from '../maid-booking/booking-data.model';
import { Subject } from 'rxjs';
import { MessageService } from '../services/message.service';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { DataShareService } from "../services/datashareservice";






//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
    selector: "booking-history",
    templateUrl: "./booking-history.html",
    styleUrls: ['../shared-css/static-page.css', 'booking-history.css'],
    encapsulation: ViewEncapsulation.None
})
export class BookingHistoryComponent implements OnInit {
    extendForm: FormGroup;
    obj: {};
    object: {};
    historyDetailsData: any;
    isData: boolean;
    isBookingType: any;
    isSelectedId: any;
    isSelectedTimeZone: any;
    createGroupForm: FormGroup;
    submitted: boolean;
    noDataFound:boolean=false;
    time: AbstractControl;
    alternate: AbstractControl;
    other: AbstractControl;
    reason: AbstractControl;
    isDeleteReason: any;
    dataLevel: any;
    isChecked: any;
    isCheckedAlternate: any;
    isCheckedOther: any;
    isTexarea: boolean;
    public bookingTabsUiDataObj: BookingTabsUiDataModel = <BookingTabsUiDataModel>{};
    @Output() dataFromServices = new EventEmitter<BookingTabsUiDataModel>();
    @Output() dataFromSchedule = new EventEmitter<BookingTabsUiDataModel>();
    pagevalue: number;
    totalPageCount: any;
    reasonErrorLog: boolean;
    reasonErrorMsg: string;
    isSelect: boolean;
    times: any;
    selectedDuration: any;
    isSelectDuration: boolean;
    reasonOfExtend: any;
    extendReasonErrorLog: boolean;
    extendReasonErrorMsg: string;
    selectedId: any;
    isExtendSelectDuration: boolean;
    isClickBackGround: boolean;
    isUserId: any;
    CurrentTime: any;
    currentDate: any;
    selectedNextDay: number;
    selectedPreviousDay: number;
    selecteddate: any;
    selectedDay: any;
    selectedDays: any;
    isVisibleOk: boolean;
    selectedBookingTime: any;
    todayTime: any;
    totalTime: any;
    selectedSplitedTime: any;
    totalSumOfTime: any;
    selectedMaidId: any;
    selectedAmount: any;
    page:any;
    allDatavar: any;
    hideText: boolean = false;
    isOkButtonVisible: boolean = false;

    constructor(
        public bookingService: BookingService,
        private spinner: NgxSpinnerService,
        public commonService: CommonService,
        private formBuilder: FormBuilder,
        private router: Router,
        public messageService: MessageService,
        private shareService: DataShareService,


    ) {
        // console.log("ts called")
    }
    ngOnInit(): void {
        this.isVisibleOk=true;
        this.isExtendSelectDuration = false;
        this.onClick('ON_GOING')
        this.isSelectDuration = false;
        this.initForm()
        this.isTexarea = false;
        this.isChecked = false;
        this.isCheckedAlternate = false;
        this.isCheckedOther = false;
        this.setDuration();
        this.initExtendForm();
        this.CurrentTime = new Date().getHours();
        this.selectedNextDay = new Date().getDate() + 1
        this.currentDate = new Date().getDate();
        this.selectedPreviousDay = new Date().getDate() - 1;
        // console.log(this.CurrentTime, this.selectedPreviousDay, this.currentDate, this.selectedNextDay, "current time")
    }



    initExtendForm() {
        this.extendForm = new FormGroup({
            reason_of_extend: new FormControl('', [Validators.required]),
        });
        // console.log(this.extendForm, "==================")
    }

    onOpenModal(selectedId, selectedReferenceId) {
        this.isClickBackGround = true;
        this.isExtendSelectDuration = false;
        localStorage.setItem('referenceId', selectedReferenceId);
        localStorage.setItem('serviceId', selectedId);
        this.selectedId = selectedId
        // console.log(this.selectedId, "helloooooo")
    }

    setDuration() {
        this.times = [];
        this.times.push("1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12");
    }

    onSelectDuration(time) {
        this.selectedDuration = time;
        this.isExtendSelectDuration = true;
    }
    onLoad(time) {
        if (this.selectedDuration == time) {
            this.isSelectDuration = true;
        } else {
            this.isSelectDuration = false;
        }
    }
    initForm() {
        // this.time = new FormControl('');
        this.alternate = new FormControl('');
        this.other = new FormControl('');
        this.reason = new FormControl('');
        this.createGroupForm = this.formBuilder.group({
            // time: this.time,
            alternate: this.alternate,
            other: this.other,
            reason: this.reason
        });
    }

    // Validation check function

    get f() { return this.createGroupForm.controls; }
    onConfirm() {
        this.submitted = true;
        if (this.createGroupForm.invalid) {
            this.spinner.hide();
            return;
        }

        this.cancel()
    }


    checkboxClick() {
        this.isSelect = true;
        this.isTexarea = false;
        this.isDeleteReason = "";
        // console.log(this.isChecked, this.isCheckedAlternate, this.isCheckedOther, "dlfjljfljdsl")
        if (!this.isChecked || this.isChecked == "0") {
            this.isSelect = true;
            this.isDeleteReason = "Time and date are not convenient"
        }
    }
    alternateCheckboxClick() {
        this.isSelect = true;
        this.isTexarea = false;
        this.isDeleteReason = "";
        // console.log(this.isChecked, this.isCheckedAlternate, this.isCheckedOther, "dlfjljfljdsl")
        if (!this.isCheckedAlternate || this.isCheckedAlternate == "1") {
            this.isSelect = true;
            this.isDeleteReason = "Found an alternate"
        }
    }
    otherCheckboxClick() {
        this.isTexarea = true;
        this.isSelect = true;
        this.reasonErrorLog = false;
        this.isDeleteReason = "";
    }

    onClick(value) {
        // localTimeZone = moment.tz.guess();
        this.isBookingType = value
        this.isData = false;
        this.obj = {
            'onBasisOfDate': value,
            'pageNo': 1,
            'limit': 10,
            // 'timeZone': moment.tz.guess(),
            'timeZone': this.getLocalTimezone(),
        }
        this.obj["uniquieAppKey"] = "892d9a5e3c21f60eb95ad196da1b0220";
        window.scrollTo(0, 0);
        this.spinner.show()
        this.bookingService.listOfAllBookingDetail(this.obj).subscribe(
            (data: any) => {
                this.spinner.hide()
                this.totalPageCount = data.data.totalCount;
                this.historyDetailsData = data.data.data;
                console.log('historydetailsdata', this.historyDetailsData);
                this.historyDetailsData.map((item,index) => {
                    console.log(item.bookingId)
                    // item.excluded_vat_amount = item.new_vat != undefined || item.new_vat != 0 ? 
                    //         (item.amount) /  (1 + item.new_vat / 100) : item.amount
                            // item.amount  = parseFloat(item.amount).toFixed(2)

                            console.log(item.amount + ' ++ + + ' +item.new_vat)
                            console.log((item.amount  * 1) /  (1 + item.new_vat / 100) , " ---- ")
                            
                            if(item.bookingType == 3){ // multiple
                                item.excluded_vat_amount = item.new_vat != undefined || item.new_vat != 0 ? 
                                (item.amount) /  (1 + item.new_vat / 100) : item.amount
                            } else {
                                item.excluded_vat_amount = item.new_vat != undefined || item.new_vat != 0 ? 
                                (item.amount) /  (1 + item.new_vat / 100) : item.amount
                            }

                            // console.log( item.excluded_vat_amount , " ++++++++++ Before aMOUNNt ++++++++")
                            // item.excluded_vat_amount = item.excluded_vat_amount.toFixed(2)

                            // console.log( parseFloat(item.excluded_vat_amount).toFixed(2), " ++++++++++ AFter aMOUNNt ++++++++")
                            // console.log( (item.excluded_vat_amount).toFixed(2), " ++++++++++ AFter aMOUNNt ++++++++")
                            
                            item.vat_amount = item.amount - item.excluded_vat_amount;
                            // this.hideText = this.hideTextWithin24Hours(item.bookingTime, item.workDate);
                            // console.log('line 251', this.hideTextWithin24Hours(item.bookingTime, item.workDate));
                            // alert(this.hideText);
                    // let excluded_vat_amount = bookingData.new_vat
					// 		? (amountPaid - bookingData.promoDiscount * no_days) /
					// 		  (1 + bookingData.new_vat / 100)
					// 		: amountPaid - bookingData.promoDiscount * no_days;


                })
                // console.log(this.historyDetailsData)
                // debugger;
                
                if (data.data.data.length == 0) {
                    this.noDataFound = true;
                }
                else{
                    this.noDataFound = false;
                }
                if (data.data.data.length > 0) {
                    this.isData = true;
                }
                // console.log(data.data, this.historyDetailsData, this.totalPageCount, "data in all view")

            },
            (error: { error: { message: string; statusCode: number }; }) => {
                this.commonService.authentication(error)
                this.spinner.hide();
                //       this.errorlog_book= true ;
                //   this.errormsg_book = error.error.message ;
                return;
            }
        )
    }

    hideTextWithin24Hours(bookingTime, date) {
        this.selectedBookingTime = bookingTime;
        this.selecteddate = new Date(parseInt(date));
        var dateMomentObject = moment(this.selecteddate, "DD/MM/YYYY");
        var dateObject = dateMomentObject.toDate().toString();
        var selectedDateTime = moment(new Date(dateObject.substr(0, 16)));

        // Assuming currentDate is the current date and time
        var currentDate = moment(); // This gets the current date and time

        // Calculate the difference in hours
        var hoursDifference = selectedDateTime.diff(currentDate, 'hours');

        // Check if the difference is less than 24 hours
        return hoursDifference < 24;
    }

    //  modal popup call
    onClickModal(serviceId, timezone, bookingTime, workDate) {
        this.isClickBackGround = true;
        this.isTexarea = false;
        this.isSelectedId = serviceId;
        this.isSelectedTimeZone = timezone;
        this.isChecked = false;
        this.isCheckedAlternate = false;
        this.isCheckedOther = false;
        this.reasonErrorLog = false;
        this.isSelect = false;

        this.hideText = this.hideTextWithin24Hours(bookingTime, workDate);
        $('#cancelConfirm').modal('show');
    }

    hideConfirmModal() {
        this.isClickBackGround = false;
        $('#cancelConfirm').modal('hide');
    }

    // cancel api implementaion


    cancel() {
        if (this.isTexarea) {
            this.isSelect = true;
            if (!this.createGroupForm.controls.reason.value) {
                this.isSelect = false;
                this.reasonErrorLog = true;
                this.reasonErrorMsg = "Reason is required"
                return;
            }
        }
        // console.log(this.isSelectedId, this.isSelectedTimeZone, this.isDeleteReason, "sdkFDSFKAKF")
        this.object = {
            'uniquieAppKey': "892d9a5e3c21f60eb95ad196da1b0220",
            "serviceId": this.isSelectedId,
            'timeZone': this.isSelectedTimeZone,
            'deleteReason': this.isDeleteReason ? this.isDeleteReason : this.createGroupForm.controls.reason.value
        }
        this.spinner.show();
        this.bookingService.cancelBooking(this.object).subscribe(
            (data: any) => {
                this.spinner.hide();
                this.isClickBackGround = true;
                $("#successfully_book_again").modal('show');
                this.createGroupForm.controls.reason.reset('');
                this.onClick(this.isBookingType);

                // console.log(data.data, "inside cancel booking")
            },
            (error: { error: { message: string; statusCode: number }; }) => {
                this.spinner.hide();
                if (error.error.statusCode == 401) {
                    this.commonService.authentication(error)
                }
                return;
            }
        )
    }

    //  book again
    bookAgain(serviceId) {
        console.log(serviceId)
        // console.log(serviceName)
       // localStorage.setItem('serviceId', serviceId);
        localStorage.setItem("isBookAgain", 'true')
        console.log(this.historyDetailsData)
        this.historyDetailsData.forEach((item) => {
           // console.log(item._id, "dksadkskdkjskjksfkjsd")
            if (item._id == serviceId) {
                item.maidId.services = item.maidId.servicesProvide;
                localStorage.setItem("maidDetails", JSON.stringify(item))
                localStorage.setItem("maidId",item.maidId._id)
                this.shareService.currentMessage.subscribe(allDatavar => this.allDatavar = allDatavar);
                this.allDatavar.serviceId =  item.services._id;
            }
        })

        // this.messageService.serviceType.next(true);
        
        //localStorage.setItem("amount",  this.selectedAmount)
        this.spinner.show();
        this.router.navigateByUrl('/hire-cleaner-in-London/schedule');
        // var dataObj = Object.assign({}, this.bookingTabsUiDataObj);
        // dataObj.uiName = "address"
        // this.dataFromSchedule.emit(dataObj);

    }
    // bookAgain2(maidId){
    //     localStorage.setItem("isMyfavouriteMaid",'true')
       
    //     this.listOfFavouritesMaid.forEach((item)=>{
    //         if(item._id==maidId){
    //             localStorage.setItem("maidDetails",JSON.stringify(item))
    //         }
    //     })
    //     this.spinner.show();
    //     this.router.navigateByUrl('/hire-cleaner-in-London');

    // }

    


    //   pagination function

    changeValue(event) {
        event = event;
        // console.log(event, "fdvfdmlgblmld")
        // this.pagevalue = (event * 20);
        this.isData = false;
        this.obj = {
            'onBasisOfDate': this.isBookingType,
            'pageNo': event,
            'limit': 10,
            // 'timeZone': moment.tz.guess(),
            'timeZone': this.getLocalTimezone(),
        }
        this.obj["uniquieAppKey"] = "892d9a5e3c21f60eb95ad196da1b0220";
        window.scrollTo(0, 0);
        this.spinner.show()
        this.bookingService.listOfAllBookingDetail(this.obj).subscribe(
            (data: any) => {
                this.spinner.hide()
                this.historyDetailsData = data.data.data;
                this.totalPageCount = (data.data.totalCount);
                if (data.data.data.length == 0) {
                    this.noDataFound = true;
                }
                else{
                    this.noDataFound = false;
                }
                if (data.data.data.length > 0) {
                    this.isData = true;
                }
                // console.log(data.data, this.historyDetailsData, this.totalPageCount, "data in all view")

            },
            (error: { error: { message: string; statusCode: number }; }) => {
                this.commonService.authentication(error)
                this.spinner.hide();
                return;
            }
        )

    }

    onExtend() {
        if (!this.selectedDuration) {
            this.extendReasonErrorLog = true;
            this.extendReasonErrorMsg = "Duration is required"
            return;
        }
        if (!this.extendForm.controls.reason_of_extend.value) {
            this.extendReasonErrorLog = true;
            this.extendReasonErrorMsg = "Reason is required"
            return;
        }
        //localStorage.setItem("isBookAgain", 'true')
        localStorage.setItem("isExtedOurJob", 'true')
        localStorage.setItem("selectedDuration", this.selectedDuration)

        this.historyDetailsData.forEach((item) => {
            // console.log(item._id, this.selectedId, "dksadkskdkjskjksfkjsd")
            if (item._id == this.selectedId) {
                localStorage.setItem("maidDetails", JSON.stringify(item))
            }
        })



        this.obj = {
            'duration': this.selectedDuration,
            'reason': this.extendForm.controls.reason_of_extend.value,
            'serviceId': this.selectedId,
            'uniquieAppKey': '892d9a5e3c21f60eb95ad196da1b0220'
        }
        window.scrollTo(0, 0);
        this.spinner.show()
        this.bookingService.ongoingExtension(this.obj).subscribe(
            (data: any) => {
                this.isClickBackGround = false;
                $("#extend_modal").modal("hide");
                this.isUserId = JSON.parse(localStorage.getItem("userData"))._id
                this.router.navigate(['/hire-cleaner-in-London/payment']);
                this.messageService.extendOurBooking.next(true);
                // console.log(data, "data in all view")
            },
            (error: { error: { message: string; statusCode: number }; }) => {
                this.commonService.authentication(error)
                this.spinner.hide();
                return;
            }
        )

    }

    onClosedModal() {
        this.isClickBackGround = false;
    }


    onClickResceduleModal(bookingTime, date,maidId,amount,serviceId,bookingType, workDate) {
        this.isOkButtonVisible = this.hideTextWithin24Hours(bookingTime, workDate);
        localStorage.setItem('serviceId', serviceId);
        localStorage.setItem('bookingType', bookingType);
        this.historyDetailsData.forEach((item) => {
            // console.log(item._id, "dksadkskdkjskjksfkjsd")
            if (item._id == serviceId) {
                localStorage.setItem("maidDetails", JSON.stringify(item))
            }
        })
        this.selectedAmount=amount;
        this.selectedMaidId=maidId;
        // console.log(this.selectedMaidId,"fffdxfddgdgdgf")
        this.selectedBookingTime = bookingTime;
        this.selecteddate = new Date(parseInt(date))
        var dateMomentObject = moment(this.selecteddate, "DD/MM/YYYY");
        var dateObject = dateMomentObject.toDate().toString();
        var date = moment(new Date(dateObject.substr(0, 16)));
        this.selectedDays = date.format("DD")
        // console.log(this.selectedDays, bookingTime, this.selectedPreviousDay, this.currentDate)
        switch (this.selectedDays) {
            case this.currentDate:
                this.isVisibleOk = false;
                break;
            case this.selectedPreviousDay:
                this.calCulateDifference(this.selectedDays);
                break;
            case this.selectedNextDay:
                this.calCulateDifference(this.selectedDays);
                break;

        }

        $("#amount").modal('show');

    }

    
    // calculating time difference
    calCulateDifference(day) {

        if (this.selectedBookingTime.split(" ")[1] == 'am') {
            this.selectedSplitedTime = parseInt(this.selectedBookingTime.split(":")[0])
            // console.log(this.selectedSplitedTime, "sabxjsajbxj")
            this.totalTime = this.selectedSplitedTime + 12;
            this.totalSumOfTime = this.CurrentTime + this.totalTime;
            if (this.totalSumOfTime > 24) {
                this.isVisibleOk = true;
            } else {
                this.isVisibleOk = false;
            }
            // console.log(this.totalSumOfTime)
        } else {
            this.selectedSplitedTime = parseInt(this.selectedBookingTime.split(":")[0])
            // console.log(this.selectedSplitedTime, "sabxjsajbxj")
            this.totalTime = this.selectedSplitedTime;
            this.totalSumOfTime = this.CurrentTime + this.totalTime;
            if (this.totalSumOfTime > 24) {
                this.isVisibleOk = true;
            } else {
                this.isVisibleOk = false;
            }
            // console.log(this.totalSumOfTime)
        }

    }

    onOkClick(){
        localStorage.setItem("isReschedule","true")
        localStorage.setItem("maidId",this.selectedMaidId)
         localStorage.setItem("amount",  this.selectedAmount)
        this.spinner.show();
        localStorage.removeItem('bookingTabType');
        if(this.isVisibleOk){
            $("#amount").modal('hide');
            this.router.navigateByUrl('/hire-cleaner-in-London/schedule');
        }else{
            $("#amount").modal('hide');
        }
        

    }


    
    onCloseModal(){
        $("#amount").modal('hide');  
    }

    private getLocalTimezone(): string {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('timezone12', timezone);
        return timezone;
    }
}