import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AllEndPoints } from '../../shared-models/end-points';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class CurrentDaysService {
    public noHeaders: HttpHeaders;
    public Headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.noHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Header': 'True' });
        this.Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if(localStorage.getItem('accessToken')){
            this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
            this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
            }
    }


    currentDaysRecord(data) {
        // let params = new HttpParams()
        // Object.keys(data).forEach(function (item) {  
        //     params = params.set(item, data[item]);
        // });
        // const url =  AllEndPoints.Booking.currentDayRecords;
        // console.log(url,"url")
        return this.http.get<any>("http://35.160.43.15:8004/user/dayWiseBookingSms?userId=5c8f8cda098a7e0ed41d7a03&type=agency")
        // return this.http.get("http://35.160.43.15:8004/user/dayWiseBookingSms?userId=5c8f8cda098a7e0ed41d7a03&type=agency").map(res => res)
    }
  
}