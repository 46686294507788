import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { ModalService } from './modal.service';
import { Router } from '@angular/router';
import { NavbarService } from '../services/navrbar.service';
declare var $: any;

@Component({ 
    selector: 'jw-modal', 
    templateUrl: 'modal.component.html', 
    styleUrls: ['modal.component.less','modal.css'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;
    isClickBackGround: boolean;

    constructor(private modalService: ModalService, private el: ElementRef, private router: Router, private _navbarSvc: NavbarService,) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
       
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }



        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'login-ui' ) {
                this.close();
            }
        })

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

   // open modal
   open(): void {
       this.isClickBackGround=true;
    this.element.style.display = 'block';
    document.body.classList.add('login-ui-open');
}

// close modal
// close(): void {
    // $('.modal').modal('hide');
    // this.isClickBackGround=true;
    // this.element.style.display = 'block';
    // document.body.classList.add('login-ui-open');
    // localStorage.clear();      
    // this.element.style.display = 'none';
    // document.body.classList.remove('login-ui-open');
    // window.location.href='/booking';
// }
    close(): void {
        // alert("-- close called  --")
        // debugger
        $('.modal').modal('hide');
        this.isClickBackGround=false;
        this._navbarSvc.loginVar = false;
    // localStorage.clear();      
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
        // this.router.navigateByUrl('/login');
        window.location.href = "/login";
        // localStorage.clear(); // Nitin
        // debugger
        // this.router.navigateByUrl('/');
        // window.location.href='/'; // nitin

    }
}