import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {  SafetyGuardsComponent } from './safety-guards.component';

export const routes: Routes = [
    { path: '', component: SafetyGuardsComponent, data: { title: "Safety | MAK.today" } },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SafetyGuardsRoutingModule { }