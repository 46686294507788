import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { currentMaidBookingRecordComponent } from './current-maid-booking';

export const routes: Routes = [
    { path: '', component: currentMaidBookingRecordComponent, data: { title: "BOOKING CLEANER RECORD | MAK.Today App" } },

    // { path: '', component: BookingHistoryComponent  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CurrentMaidBookingRecordRoutingModule { 
    constructor() {
        // console.log('booking routing called !');
    }
}