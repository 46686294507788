import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MaidPrivacyPolicyComponent } from './maid-privacy.component';

export const routes: Routes = [
    { path: '', component: MaidPrivacyPolicyComponent, data: { title: "Privacy Policy | MAK.today" } },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MaidPrivacyPolicyRoutingModule { }