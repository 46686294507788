import { Component , ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';
import { MyFavouritesService } from '../services/my-favourites.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment-timezone';
import { CommonService } from '../services/common.service';




//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
    selector: "my-favourites",
    templateUrl: "./my-favourites.html",
    styleUrls: ['../shared-css/static-page.css'], 
    encapsulation: ViewEncapsulation.None
})
export class MyFavouritesComponent implements OnInit {
    obj: {};
    listOfFavouritesMaid: any;
    isData: boolean;
    isBookingType: any;
    object: { uniquieAppKey: string; maidId: any; };
    isDuplicate: boolean;
 
    constructor(
public myFavouritesService : MyFavouritesService ,
private spinner: NgxSpinnerService, // private modal: 
private router: Router,
public commonService:CommonService,
    ) {
    //   console.log("ts called")
    } 
    ngOnInit(): void {
        this.onClick()
     
        }


        onClick(){
            this.isData = false;
            this.obj={
                'uniquieAppKey':"892d9a5e3c21f60eb95ad196da1b0220",
                 'pageNo':1,
                  'limit':10,
                   }
            window.scrollTo(0, 0);
            this.spinner.show()
            this.myFavouritesService.listOfAllFavouritesMaids(this.obj).subscribe(
                (data: any)  => {
                    this.spinner.hide()
                    this.listOfFavouritesMaid = data.data;
                    if(data.data.length>0){
                        this.isData = true;
                    }
                    // console.log(data.data,data.data.length, this.listOfFavouritesMaid,"data in all view")
    
                },
                (error: { error: { message: string;statusCode:number }; })  => {
                    this.commonService.authentication(error)
                    this.spinner.hide();
                //       this.errorlog_book= true ;
                //   this.errormsg_book = error.error.message ;
                      return;
                  }
            )
        }
     
         //  book again
    bookAgain(maidId){
        localStorage.setItem("isMyfavouriteMaid",'true')
       
        this.listOfFavouritesMaid.forEach((item)=>{
            if(item._id==maidId){
                localStorage.setItem("maidDetails",JSON.stringify(item))
            }
        })
        this.spinner.show();
        this.router.navigateByUrl('/hire-cleaner-in-London');

    }

    langs(lang) {
        var Output = [];
        if (lang) {
            lang.forEach(element => {
                this.isDuplicate = false;
                if (Output.length > 0) {
                    Output.forEach((item) => {
                        if (item == element.languageName) {
                            this.isDuplicate = true;

                        }
                    })
                }

                if (!this.isDuplicate) {
                    Output.push(element.languageName);
                }
                // console.log(Output,"language array")
            });
            return Output.join(", ");
        }

    }

    async getFavouriteMaidsDetails(id: any) {
        console.log(id)
        // this.router.navigate(['my-favourites/my-favourites-detail/' +id]);
    
      }
    redirectToHome(){
        this.router.navigateByUrl('/hire-cleaner-in-London');
    }
      // remove favourite Cleaner api implementaion

      removeFavouriteMaids(maidId){
        this.object = {
           'uniquieAppKey':"892d9a5e3c21f60eb95ad196da1b0220",
              'maidId': maidId,
        }
        this.spinner.show();
        this.myFavouritesService.removeFavouriteMaid( this.object ).subscribe(
            (data: any)  => {
                //  this.spinner.hide();
                this.onClick();
                // console.log(data.data,"inside favourite maids")
            },
            (error: { error: { message: string;statusCode:number }; })  => {
                this.spinner.hide();
                if(error.error.statusCode==401){
                    this.commonService.authentication(error)
                }
                  return;
              }
        ) 
    }

}