import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { MaidBookingService } from '../../services/maid-booking.service';
import { NgxSpinnerService } from "ngx-spinner";
// import { SearchUsersmaid, getMaidDetailsss } from '../../maid-booking/booking-data.model';
import { SearchUsersmaid, getMaidDetailsss } from '../../maid-booking/booking-data.model';
import { MyFavouritesService } from 'src/app/services/my-favourites.service';
import { CommonService } from '../../services/common.service';
@Component({
  selector: 'app-my-favourites-listing',
  templateUrl: './my-favourites-listing.component.html',
  styleUrls: ['./my-favourites-listing.component.css']
})
export class MyFavouritesListingComponent implements OnInit {
  myFavouriteId:string;
  selectedMaidId:string;
  currency:string;
  actualPrice:any;
  totalrating: any;
  data1:any;
  object:any;
  availableMaidCount: any;
  cleanersCount: any;
  ironingCount: any;
  cookingCount: any;
  childrenCareCount: any;
  cleanersCountOfMaids: any;
  ironingCountOfMaids: number;
  cookingCountCountOfMaids: number;
  childrenCareCountOfMaids: number;
  totalReviewCount: any;
  avgMaidReviewCount: number[];
  childCountOfMaids: number;
  cookingMaidCountCountOfMaids: number;
  ironingMaidsCountOfMaids: number;
  reviewData: any;
  totalSumOfMaidRating:any
  servicesProvide:any []=[];
  experience:string;
  isDuplicate: boolean;
  listOfFavouritesMaid: any;
  resData:any={};
  MaidsDetails: getMaidDetailsss = <getMaidDetailsss>{};;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private maidBookingSvc: MaidBookingService,
    private spinner: NgxSpinnerService,
    public myFavouritesService : MyFavouritesService ,
    public commonService:CommonService,
  ) { }

  ngOnInit() {
     this.myFavouriteId = this.route.snapshot.params.id;
        if (this.myFavouriteId) {
           console.log(this.myFavouriteId)
            this.getMaiddetails(this.myFavouriteId)
          }
  }
  getMaiddetails(_ID) {
    console.log(_ID)
       window.scrollTo(0, 0);
       this.MaidsDetails.maidId = _ID;
       this.MaidsDetails.uniquieAppKey = '892d9a5e3c21f60eb95ad196da1b0220';
      //  if (!this.isShowSpinner) {
      //      this.spinner.show()
      //  }
       
       this.maidBookingSvc.getMaiddetail(this.MaidsDetails).subscribe(
           (data: any) => {
            console.log("ashish" +data)
            this.resData=data.data;
            this.experience=this.resData.experience;
            this.currency=this.resData.currency;
            this.servicesProvide=this.resData.servicesProvide
            this.data1=this.resData.reviewData
            this.actualPrice=this.resData.actualPrice;
            console.log(this.resData)
            console.log(this.resData.currency)
           }
       )
   }
   bookAgain(maidId:any){
    console.log(maidId)
    localStorage.setItem("isMyfavouriteMaid",'true')
    localStorage.setItem("maidDetails",JSON.stringify(this.resData))
    this.spinner.show();
    this.router.navigateByUrl('/hire-cleaner-in-London');

}
  //  redirectToHome(){
  //   this.router.navigateByUrl('/hire-cleaner-in-London');
  //  }
   langs(lang) {
    var Output = [];
    if (lang) {
        lang.forEach(element => {
            this.isDuplicate = false;
            if (Output.length > 0) {
                Output.forEach((item) => {
                    if (item == element.languageName) {
                        this.isDuplicate = true;

                    }
                })
            }

            if (!this.isDuplicate) {
                Output.push(element.languageName);
            }
            // console.log(Output,"language array")
        });
        return Output.join(", ");
    }

}
services(services) {
  var Output = [];
  if (services) {
      services.forEach(service => {
          this.isDuplicate = false;
          if (Output.length > 0) {
              Output.forEach((item) => {
                  if (item == service.name) {
                      this.isDuplicate = true;

                  }
              })
          }

          if (!this.isDuplicate) {
              Output.push(service.name);
          }
          // console.log(Output,"language array")
      });
      return Output.join(", ");
  }

}
totalReviewsCount() {
  this.cleanersCount = this.resData.avgCleaning;
  this.ironingCount = this.resData.avgIroning;
  this.cookingCount = this.resData.avgCooking;
  this.childrenCareCount = this.resData.avgChildCare;
  if (this.cleanersCount > 0) {
      this.cleanersCountOfMaids = 1;
  }
  if (this.ironingCount > 0) {
      this.ironingCountOfMaids = 1;
  }
  if (this.cookingCount > 0) {
      this.cookingCountCountOfMaids = 1;
  }
  if (this.childrenCareCount > 0) {
      this.childrenCareCountOfMaids = 1;
  }
  this.totalrating = (this.cleanersCount + this.ironingCount + this.cookingCount + this.childrenCareCount) / (this.childrenCareCountOfMaids + this.cookingCountCountOfMaids + this.ironingCountOfMaids + this.cleanersCountOfMaids)
  // console.log(this.totalrating, "total rating")
}
maidReviewCount(data1) {
  this.reviewData = data1;

  // console.log(this.reviewDataLength, "review data")
  this.avgMaidReviewCount = [];
  data1.map((item, index) => {
      this.childCountOfMaids = 0;
      this.cleanersCountOfMaids = 0;
      this.cookingMaidCountCountOfMaids = 0;
      this.ironingMaidsCountOfMaids = 0;
      if (item.maidRating.childCare > 0) {
          this.childCountOfMaids = 1;
      }
      if (item.maidRating.cleaning > 0) {
          this.cleanersCountOfMaids = 1;
      }
      if (item.maidRating.cooking > 0) {
          this.cookingMaidCountCountOfMaids = 1;
      }
      if (item.maidRating.ironing > 0) {
          this.ironingMaidsCountOfMaids = 1;
      }
      // this.avgMaidReviewCount.push(((item.maidRating.childCare + item.maidRating.cleaning+item.maidRating.cooking+item.maidRating.ironing)/(this.childCountOfMaids + this.cleanersCountOfMaids + this.cookingMaidCountCountOfMaids + this.ironingMaidsCountOfMaids)));
      this.reviewData[index].avgReviews = ((item.maidRating.childCare + item.maidRating.cleaning + item.maidRating.cooking + item.maidRating.ironing) / (this.childCountOfMaids + this.cleanersCountOfMaids + this.cookingMaidCountCountOfMaids + this.ironingMaidsCountOfMaids));
      this.totalSumOfMaidRating = this.childCountOfMaids + this.cleanersCountOfMaids + this.cookingMaidCountCountOfMaids + this.ironingMaidsCountOfMaids;
      // console.log(this.reviewData[index].avgReviews, "average maids count according to index")
      if (this.reviewData[index].avgReviews == 0 || this.totalSumOfMaidRating == 0) {
          this.reviewData.splice(index, 1);
      }
  })
  this.totalReviewCount = this.reviewData.length;
  // console.log(this.totalReviewCount, "jcbajscjsdbcfjbsdjbfjbjsbfjbgbjsb")
  // console.log(this.reviewData, "average maids count")
}
removeFavouriteMaids(maidId){
  this.object = {
     'uniquieAppKey':"892d9a5e3c21f60eb95ad196da1b0220",
        'maidId': maidId,
  }
  this.spinner.show();
  this.myFavouritesService.removeFavouriteMaid( this.object ).subscribe(
      (data: any)  => {
          //  this.spinner.hide();
          this.router.navigateByUrl('/my-favourites');
      },
      (error: { error: { message: string;statusCode:number }; })  => {
          this.spinner.hide();
          if(error.error.statusCode==401){
              this.commonService.authentication(error)
          }
            return;
        }
  ) 
}

}
