import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup-component';
import { SignupRoutingModule } from './signup.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedByAppModule } from '../../shared-by-app/shared-module';
import { BsModule } from '../../shared-by-app/modules/bs.module';

import {
  SocialLoginModule, 
  AuthServiceConfig,
  GoogleLoginProvider, 
  FacebookLoginProvider, 
} from 'ng4-social-login';
import { environment } from 'src/environments/environment';

const CONFIG = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleClientID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookID)
  }
],true);
 
export function provideConfig() {
  return CONFIG;
}
@NgModule({
  declarations: [SignupComponent],
  imports: [
    CommonModule,
    SignupRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedByAppModule,
    SocialLoginModule,
    BsModule,
    // OtpModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  exports: [SignupComponent],
})
export class SignupModule { }
