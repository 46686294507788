import { Injectable } from "@angular/core";
import 'rxjs/add/operator/map';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    serviceType= new Subject<any>();
    tabType= new Subject<any>();
    cancelPaymentMsg= new Subject<any>();
    googleSignInData = new Subject<any>();
    noMaidFoundData = new Subject<any>();
    extendOurBooking = new Subject<any>();
    navbarHiding = new Subject<any>();

    private isSocialLoginSubject = new BehaviorSubject<boolean>(false);
    public isSocialLogin$ = this.isSocialLoginSubject.asObservable();

    setIsSocialLogin(value: boolean) {
        this.isSocialLoginSubject.next(value);
    }
    

}