import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings';

export const routes: Routes = [
    { path: '', component: SettingsComponent, data: { title: "Settings | MAK.today" } },

    // { path: '', component: BookingHistoryComponent  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule { 
    constructor() {
        // console.log('booking routing called !');
    }
}