import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[activeOnClick]',
    host: {
        '[class.active-item]': 'clicked',
        '(click)': '_toggle()'
    }
})
export class ActiveOnClickDirective {
    public lastElementId: string;
    @Input('activeOnClick')
    set value(val) {
        if (val) {
            console.log(val)
        }
    }
    clicked = false;

    _toggle() {
        this.clicked = !this.clicked;
    }
}