import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';
@Component({
    selector: "inner-navbar-ui",
    templateUrl: "./inner-navbar.html",
    styleUrls: ['./inner-navbar.css']
})
export class InnerNavbarComponent implements OnInit, OnDestroy {
    constructor(private router: Router) {

    }
    ngOnInit() {

    }
    logOut() {
        localStorage.clear();//Nitin
        debugger
        this.router.navigateByUrl('/login');
    }
    ngOnDestroy() {

    }

}