import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtpRoutingModule } from './otp-routing.module';
import { OtpComponent } from './otp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedByAppModule } from 'src/app/shared-by-app/shared-module';
import { BsModule } from 'src/app/shared-by-app/modules/bs.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment.prod';


@NgModule({
  declarations: [OtpComponent],
  imports: [
    CommonModule,
    OtpRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedByAppModule,
    BsModule,
    AngularFireModule.initializeApp(environment.fireBaseConfig),
    AngularFireAuthModule
  ],
  exports: [OtpComponent],
})
export class OtpModule { }
