import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { currentDayBookingComponent } from './current-booking';

export const routes: Routes = [
    { path: '', component: currentDayBookingComponent, data: { title: "BOOKING RECORD | MAK.today" } },

    // { path: '', component: BookingHistoryComponent  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CurrentBookingRoutingModule { 
    constructor() {
        // console.log('booking routing called !');
    }
}