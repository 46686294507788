import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MaidsTermsAndConditionsComponent } from './maid-term.component';

export const routes: Routes = [
    { path: '', component: MaidsTermsAndConditionsComponent, data: { title: "Terms and Conditions | MAK.today" } },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MaidsTermsAndConditionsRoutingModule { }