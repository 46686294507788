import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BookingHistoryComponent } from './booking-history';

export const routes: Routes = [
    { path: '', component: BookingHistoryComponent, data: { title: "BOOKING HISTORY | MAK.today App" } },

    // { path: '', component: BookingHistoryComponent  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BookingRoutingModule { 
    constructor() {
        // console.log('booking routing called !');
    }
}