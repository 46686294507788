import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navrbar.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { LoginService } from '../account/login';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Mak-Today';
  public isFooterRender: boolean;
  routeParameters: any;
  typeParams: any;
  isVisiblenavbar: boolean;
  constructor(
    public navbarSvc: NavbarService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: LoginService,
    private route: ActivatedRoute,
    public messageService:MessageService
  ) {
      // if (location.protocol === 'http:') {
      //   window.location.href = location.href.replace('http', 'https');
      // }
    setTimeout(() => {
      this.isFooterRender = true;
    }, 1500);
  }
  
    openModal(id: string) {
      alert();
      this.modalService.open(id);
    }

    closeModal(id: string) {
      this.modalService.close(id);
    }

  ngOnInit() {
    this.isVisiblenavbar=false;
   /*  setTimeout(()=>{ 
      this.isVisiblenavbar=false;
     }, 100); */
    
    this.route.queryParams
        .subscribe(params => {
          console.log('params app component', params);
           this.routeParameters=params.userId;
           this.typeParams=params.type;
           if(this.routeParameters){
             this.isVisiblenavbar=true;
           }
     
        });
    
    

    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            window.scrollTo(0, 0);
            return child.snapshot.data['title'];
          }
          window.scrollTo(0, 0);
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
        window.scrollTo(0, 0);
      });
  }


  public options = {
    position: ["bottom", "right"],
    timeOut: 5000,
    lastOnBottom: true
  }


  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  
  }

  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  reloadWindow() {
    window.location.reload();
  }
}
