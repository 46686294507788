import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login-component';
import { LoginRoutingModule } from './login.routing';
import {
    SocialLoginModule, 
    AuthServiceConfig,
    GoogleLoginProvider, 
    FacebookLoginProvider, 
    LinkedinLoginProvider
  } from 'ng4-social-login';
import { environment } from 'src/environments/environment';
  
  const CONFIG = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleClientID)
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookID)
    }
  ],true);
   
  export function provideConfig() {
    return CONFIG;
  }
@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule

    ],
    providers: [  {
        provide: AuthServiceConfig,
        useFactory: provideConfig
      }],
    exports: [LoginComponent],


})

export class LoginModule {
    constructor() {
        console.log('login called !');
    }
}

