import { Component, OnInit, OnDestroy } from "@angular/core";
@Component({
    selector: "footer-ui",
    templateUrl: "./footer.html",
    styleUrls: ['./footer.css','../../../../shared-css/static-page.css']
})
export class FooterComponent implements OnInit, OnDestroy {
    constructor() {

    }
    ngOnInit() {

    }
    ngOnDestroy() {

    }

}