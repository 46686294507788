import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { AllEndPoints } from '../shared-models/end-points';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class MyFavouritesService {
    public noHeaders: HttpHeaders;
    public Headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.noHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Header': 'True' });
        this.Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.Headers = new HttpHeaders();
        
        if(localStorage.getItem('accessToken')){
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
    }
}

   listOfAllFavouritesMaids(data) {
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url =  AllEndPoints.MyFavourites.listOfAllFavouritesMaidService;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    addFavouriteMaid(data) {
        const url = AllEndPoints.MyFavourites.addFavouritesMaid;
        return this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
    }

    removeFavouriteMaid(data) {
        const url = AllEndPoints.MyFavourites.removeFavouritesMaid;
        return this.http.post(url, data, { headers: this.Headers })
            .map(res => res)
    }

}