import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisinfectionRoutingModule } from './disinfection.routing';
import { DisinfectionComponent } from './disinfection.component';



@NgModule({
  declarations: [DisinfectionComponent],
  imports: [
    CommonModule,
    DisinfectionRoutingModule 
  ]
})
export class DisinfectionModule { }
