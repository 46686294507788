import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomMessageService } from '../services/custom-message.service';
import { Router } from '@angular/router';
import { NavbarService } from './navrbar.service';
import { ModalService } from '../_modal';




@Injectable({
  providedIn: 'root'
})
export class CommonService {
  showloader: boolean = false;
  public get navbarSvc(): NavbarService {
    return this._navbarSvc;
}
public set navbarSvc(value: NavbarService) {
    this._navbarSvc = value;
}

  constructor(private http: HttpClient, private customMessage: CustomMessageService, private router: Router, private _navbarSvc: NavbarService,public modalService:ModalService) {}

  

  public handleError(err: HttpErrorResponse) {
    // this.errorMessage = err.message;
    this.customMessage.showMessage({
      severity: 'error',
      summary: 'error',
      detail: err.message
    });
  }
  public authentication(error){
    console.log(error,error.error.statusCode)
    if(error.error.statusCode==401){
      this.modalService.open('custom-modal-15'); 
      // alert("Your session has been expired. Please login again.")
      // this._navbarSvc.loginVar = false;
      // localStorage.clear();
      // window.location.href='/';
      // this.router.navigateByUrl('/');
  }
  }
  postApi(url, payload, options?): Promise<any> {
    return this.http.post(url, payload, options).toPromise();
  }

  putApi(url, payload, options?): Promise<any> {
    return this.http.put(url, payload, options).toPromise();
  }

  getApi(url, options): Promise<any> {
    return this.http.get(url, options).toPromise();
  }
  
}
