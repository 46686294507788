import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { CurrentMaidBookingRecordRoutingModule } from './current-maid-booking.routing';
import { currentMaidBookingRecordComponent } from './current-maid-booking';





@NgModule({
  declarations: [currentMaidBookingRecordComponent],
  imports: [
    CommonModule,
    HttpClientModule ,
    CurrentMaidBookingRecordRoutingModule ,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [
    currentMaidBookingRecordComponent
],
})
export class CurrentMaidBookingRecordModule {
  constructor() {
    // console.log('booking module called !');
}
 }
