import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export interface Message {
  severity?: string;
  summary?: string;
  detail?: string;
  id?: any;
  marketplace_user_id?: number;
}

@Injectable()
export class CustomMessageService {
  constructor(private messageService: MessageService) {
  }
  showMessage(obj: Message) {
    this.messageService.clear();
    this.messageService.add({
      severity: obj.severity,
      // summary: obj.summary,
      detail: obj.detail.toString(),
      // sticky: true // for inspecting
    });
  }
}
