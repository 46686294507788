import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { AllEndPoints } from '../shared-models/end-points';
import { SearchUsersmaid , getMaidDetailsss } from '../maid-booking/booking-data.model';
import 'rxjs/add/operator/map';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MaidBookingService {
    public noHeaders: HttpHeaders;
    public Headers: HttpHeaders;
    public params: HttpParams;
   
    constructor(private http: HttpClient, private spinner: NgxSpinnerService) {
        

        this.noHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Header': 'True' });
        this.Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.Headers = new HttpHeaders();

        if(localStorage.getItem('accessToken')){
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
        }


    }


    getMaidBookingServices() {
        this.spinner.show();
        const url = AllEndPoints.MaidBooking.getMaidBookingServices;
        return this.http.get(url, { headers: this.Headers })
            .map((res) => {
                if(res){
                    this.spinner.hide();
                    return res
                }
                
            });
    }
    
    getMaidlisting(data:SearchUsersmaid) {
        console.log("data service ts file*******")
        console.log(data)
        let params = new HttpParams()
        let paymentdata : any = [];
        Object.keys(data).forEach(function (item) {  
         //   console.log(item+"  ====== "+data[item])
            if(item == "propertyType"){
                paymentdata = data[item]
                // data[item] = paymentdata
                // params = params.set(item, data[item]);
            //     // data[item][0] = data[item]
            //     console.log(paymentdata.push(data[item]))
            //     params = params.set(item, paymentdata.push(data[item]));
            // } else {
            //     params = params.set(item, data[item]);
            }
            params = params.set(item, data[item]);
        });
        console.log("*********** service ts file*******")
        console.log(params)
        const url = AllEndPoints.MaidBooking.getMaidlisting;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    getAllAgencylisting(data) {
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        
        const url = AllEndPoints.MaidBooking.getAllAgencylisting;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }
    getAllLanglisting(data) {
        
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.getAllLanglisting;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }
    getAllNationlisting(data) {
        
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.getAllNationalitylisting;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }
    getAllReligionlisting(data) {
        
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.getAllReligionlisting;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    getMaiddetail(data:getMaidDetailsss) {
 
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.getMaidDetail;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    bookAgain(data) {
      
        const url = AllEndPoints.MaidBooking.getBookAgain;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }
    bulkBookAgain(data) {
      
        const url = AllEndPoints.MaidBooking.getBulkBookAgain;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    listOfBulkMaids(data) {
        const url = AllEndPoints.MaidBooking.getListBulkMaids;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }
  

    searchBulkMaid(data) {
 
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.searchBulkMaid;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    getBulkMaidDetail(data:getMaidDetailsss) {
 
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.listOfBulkMaidUser;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    getCards(data) {
        // let params = new HttpParams()
        // Object.keys(data).forEach(function (item) {  
        //     params = params.set(item, data[item]);
        // });
        const url = AllEndPoints.MaidBooking.getCards;
        return this.http.get(url, { headers: this.Headers }).map(res => res)
    }

    deleteCard(data) {
        data.uniquieAppKey  = environment.uniqueKey;
        const url = AllEndPoints.MaidBooking.deleteCard;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    BookMaiddetail(data) {

        const url = AllEndPoints.MaidBooking.bookMaidDetail;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    checkMaidDetails(data){
        let params = new HttpParams()
        Object.keys(data).forEach(function (item) {  
            params = params.set(item, data[item]);
        });
        const url = AllEndPoints.MaidBooking.getcheckMaidAvailable;
        return this.http.get(url, { headers: this.Headers , params:params}).map(res => res)
    }

    onApplyPromoCode(data) {

        const url = AllEndPoints.MaidBooking.promoCode;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }
    bulkBooking(data) {

        const url = AllEndPoints.MaidBooking.bookBulkMaidUser;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    bulkBookMaidPay(data) {

        const url = AllEndPoints.MaidBooking.bulkBookMaidpay;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }
    
    createPayment(data) {
        const url = AllEndPoints.MaidBooking.createPayment;
        data.uniquieAppKey = environment.uniqueKey;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    BookMaidpay(data) {
        data.uniquieAppKey = environment.uniqueKey;
        const url = AllEndPoints.MaidBooking.bookMaidpay;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    
    BookMaidpayFailed(data) {
        // const url = 'http://localhost'
        const url = AllEndPoints.MaidBooking.bookFailedpay;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }
   
    createCharge(data) {

        const url = AllEndPoints.MaidBooking.chargeTap;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    tapChargeDetail(data){
        const url = AllEndPoints.MaidBooking.tapChargeDetail;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }

    getStartServices() {
        this.spinner.show();
        const url = AllEndPoints.StartServices.getStartServices;
        return this.http.get(url, { headers: this.Headers })
            .map((res) => {
                if(res){
                    this.spinner.hide();
                    return res;
                }
                
            });
    }

    addCard(data){
        const url = AllEndPoints.MaidBooking.addCard;
        data.uniquieAppKey = environment.uniqueKey;
        return this.http.post(url, data, { headers: this.Headers }).map(res => res)
    }


    // send email
    sendEmail() {
        this.spinner.show();
        const url = AllEndPoints.MaidBooking.sendEmail;
        return this.http.get(url, { headers: this.Headers })
            .map((res) => {
                if(res){
                    this.spinner.hide();
                    return res
                }
                
            });
    }
 

}