import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { NavbarService } from "../services/navrbar.service";
import { WhyUsModel } from "./home.model";
import { ActivatedRoute, Routes, RouterModule, Router } from "@angular/router";
// import { LoginService } from "../account/login";
// import { CommonService } from "../services/common.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "../services/account/account.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

declare var $: any;

declare global {
  interface Window { Trustpilot: any; }
}

window.Trustpilot = window.Trustpilot || {};


@Component({
  selector: "home-ui",
  templateUrl: "./home.html",
  styleUrls: ["./home.css", "../shared-css/static-page.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  public whyUs: WhyUsModel[] = [];
  public whyUsObj: WhyUsModel = <WhyUsModel>{};
  public responsiveOptions = [];
  createJobForm: FormGroup;
  @ViewChild("sliderContent", { static: false }) sliderElement: ElementRef;
  formdata: FormData;
  errorlog: boolean;
  errormsg: string;
  submitted: boolean;
  object: {};
  routeParameters: any;
  isClickBackGround: boolean;

  constructor(
    private navbarSvc: NavbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private modalService: LoginService,
    // public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private accountSvc: AccountService,
    private route: ActivatedRoute
  ) {
    console.log(" -- HOME PAGE !!! ")
    this.navbarSvc.loginVar = false;
    // this.navbarSvc.showSimpleNavbar();
    // this.navbarSvc.showFooter();
    this.navbarSvc.hideBothNavbar()
    // this.navbarSvc.hideFooter()

    this.createWhyUseContent();
    this.carouselOptions();
    this.getQueryParam();
    
  }

  ngOnInit() {
    const trustboxRef = document.getElementById('trustbox');
    window.Trustpilot.loadFromElement(trustboxRef)


    this.route.params.subscribe((params) => {
      this.routeParameters = params.id;
      if (this.routeParameters) {
        this.verifyMail();
      }
    });
    if (!this.routeParameters) {
      $("#safety_guards").modal("show");
    }
    // console.log(this.routeParameters,"router prams")
    this.initCreateJobForm();
  }

  getQueryParam() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const target = params["target"];
      if (target === "app") {
        this.scrollToApp();
      }
    });
  }

  scrollToApp() {
    $(document).ready(function () {
      // Handler for .ready() called.
      $("html, body").animate(
        {
          scrollTop: $("#what").offset().top,
        },
        "slow"
      );
    });
  }

  carouselOptions() {
    this.responsiveOptions = [
      {
        breakpoint: "2000px",
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: "1024px",
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  createWhyUseContent() {
    this.whyUsObj.Title = "Bulk booking";
    this.whyUsObj.Image =
      "../../assets/landing-imgs/new/Bulk booking, get group of maids.jpg";
    this.whyUsObj.Content =
      "Our MAK.today app allows you to book 2, 3 or up to 100 cleaners or cleaners for the day in just 30 seconds and tomorrow when you open your door, you will find 100 cleaners and cleaners standing at your doorstep infront of you, ready to serve you.";
    this.whyUs.push(this.whyUsObj);

    this.whyUsObj = <WhyUsModel>{};
    this.whyUsObj.Title = "Plan ahead / regular/ multiple booking";
    this.whyUsObj.Image =
      "../../assets/landing-imgs/new/Plan ahead, multiple and regular bookings.jpg";
    this.whyUsObj.Content =
      "Booking a cleaner throughout the month on an app has never been easier. The MAK.today allows you to schedule and book your cleaner or cleaners for multiple days throughout the month, in one booking.";
    this.whyUs.push(this.whyUsObj);

    this.whyUsObj = <WhyUsModel>{};
    this.whyUsObj.Title = "Quality – experienced / 5 star hotel cleaners";
    this.whyUsObj.Image =
      "../../assets/landing-imgs/new/Experienced & Trusted, high-quality, high-rated maids.jpg";
    this.whyUsObj.Content =
      "MAK.today can bring experienced 5 star hotel cleaners and cleaners at your doorstep to ensure quality while cleaners and cleaners are regularly reviewed for your viewing.";
    this.whyUs.push(this.whyUsObj);

    this.whyUsObj = <WhyUsModel>{};
    this.whyUsObj.Title = "Flexible and reliable";
    this.whyUsObj.Image =
      "../../assets/landing-imgs/new/Flexble and reliable services for Home or Business.jpg";
    this.whyUsObj.Content =
      "MAK.today caters to all your requirements.You can either create an account on our website or just download our mobile app, source the cleaners from multiple agencies and individual labour, and filter by various options, from those working near you. Got a party at your place? Why not bulk book multiple cleaners.";
    this.whyUs.push(this.whyUsObj);

    this.whyUsObj = <WhyUsModel>{};
    this.whyUsObj.Title = "Easy payment options";
    this.whyUsObj.Image =
      "../../assets/landing-imgs/new/Easy payment options, we like when you smile.jpg";
    this.whyUsObj.Content =
      "Simply pay with your mobile phone as we accept payments through debit and credit cards. Cash payment options are also available.";
    this.whyUs.push(this.whyUsObj);

    this.whyUsObj = <WhyUsModel>{};
    this.whyUsObj.Title = "24/7 service";
    this.whyUsObj.Image = "../../assets/landing-imgs/new/24_7Service.jpg";
    this.whyUsObj.Content =
      "At MAK.today, our aim is to go beyond basic cleaning. You can book a cleaner at a date and time that suits you, 24 hours a day, 7 days a week and 365 days a year.";
    this.whyUs.push(this.whyUsObj);
  }

  redirectToPage() {
    if (this.navbarSvc.loginVar == false) {
      // this.modalService.open("custom-modal-12");
      this.router.navigate(['/'])
      
    } else {
      this.spinner.show();
      this.router.navigateByUrl("/hire-cleaner-in-London");
    }
    localStorage.removeItem("bookingTabType");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isReschedule");
    localStorage.removeItem("maidId");
    localStorage.removeItem("amount");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("serviceId");
    localStorage.removeItem("isBookAgain");
    localStorage.removeItem("maidDetails");
    localStorage.removeItem("isMyfavouriteMaid");
    localStorage.removeItem("isCancelPayemnt");
    localStorage.removeItem("maidId");
    localStorage.removeItem("isBack");
    localStorage.removeItem("bookingTabType");
  }

  ngOnDestroy() {}

  // Validation function

  get f() {
    return this.createJobForm.controls;
  }

  initCreateJobForm() {
    this.createJobForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      contact_number: new FormControl("", [Validators.required]),
      message: new FormControl("", [Validators.required]),
    });
    // console.log(this.createJobForm, "==================")
  }

  // Contact us api
  contactUs() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.createJobForm.invalid) {
      this.spinner.hide();
      return;
    }
    this.object = {
      name: this.createJobForm.controls.name.value,
      email: this.createJobForm.controls.email.value,
      comment: this.createJobForm.controls.message.value,
      phoneNumber: this.createJobForm.controls.contact_number.value,
      uniquieAppKey: "892d9a5e3c21f60eb95ad196da1b0220",
      type: "USER",
    };

    // this.accountSvc.contactUs(this.object).subscribe(
    //   (data: any) => {
    //     this.spinner.hide();
    //     this.submitted = false;
    //     this.createJobForm.reset();
    //     this.isClickBackGround = true;
    //     $("#enquery_updte").modal("show");
    //   },
    //   (error: { error: { message: string; statusCode: number } }) => {
    //     this.spinner.hide();

    //     return;
    //   }
    // );
  }

  onCloseModal() {
    this.isClickBackGround = false;
    $("#safety_guards").modal("hide");
  }

  closeModal() {
    $("#safety_guards").modal("hide");
    this.router.navigateByUrl("safety");
  }

  // Contact us api
  verifyMail() {
    this.spinner.show();
    this.object = {
      userId: this.routeParameters,
    };
    this.accountSvc.verifyMail(this.object).subscribe(
      (data: any) => {
        // console.log(data)
        this.spinner.hide();
        this.isClickBackGround = true;
        $("#email_verify").modal("show");
      },
      (error: { error: { message: string; statusCode: number } }) => {
        // console.log("Error = "+JSON.stringify(error))
        // console.log("Error = "+error.error.statusCode)
        if (error.error.statusCode == 400) {
          this.spinner.hide();
          this.isClickBackGround = true;
          $("#email_already_verify").modal("show");
        }
        this.spinner.hide();
        return;
      }
    );
  }

  redirectToRegister(){
    this.router.navigate(['/register']);
  }

  redirectTologin(){
    this.router.navigate(['/login'])
  }
}
