import { Component , ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { BookingService } from '../services/booking.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment-timezone';
import { CommonService } from '../services/common.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { BookingTabsUiDataModel, MaidServiceResponseModel, MaidServiceModel } from '../maid-booking/booking-data.model';
import {  Subject} from 'rxjs';
import { MessageService } from '../services/message.service';
import 'sweetalert2/src/sweetalert2.scss';
import { CurrentDaysService } from '../services/current-day-records.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AllEndPoints } from '../shared-models/end-points';
import { NavbarService } from "../services/navrbar.service";
declare var $: any;
@Component({
    selector: "current-maid-booking",
    templateUrl: "./current-maid-booking.html",
    styleUrls: ['current-maid-booking.css'], 
    encapsulation: ViewEncapsulation.None
})
export class currentMaidBookingRecordComponent implements OnInit {
    obj:{};
    selectedDayRecord: any;
    routeParameters: any;
    typeParams: any;
    selectedWorkingDate: any;
    isCurrentDayRecords: boolean;
  offset: any;

    constructor(
public bookingService:BookingService,
private spinner: NgxSpinnerService,
public commonService:CommonService,
private formBuilder: FormBuilder,
private router: Router,
public messageService:MessageService,
private route: ActivatedRoute,
private CurrentDaysService: CurrentDaysService,
private http: HttpClient,
private navbarSvc: NavbarService
    ) {
      // console.log("ts called")
      this.navbarSvc.hideBothNavbar()
      this.navbarSvc.hideFooter();
    } 
    ngOnInit(): void {
     // console.log( this.route.snapshot.paramMap.get('id'));
     // console.log(window.location.pathname.split( '/' ));
      //console.log( this.route.snapshot.paramMap.get('id').length);
      
      if(this.route.snapshot.paramMap.get('id').match(/^[0-9a-fA-F]{24}$/)){
        console.log("true", new Object(this.route.snapshot.paramMap.get('id')));
      }else{
        console.log("false", new Object(this.route.snapshot.paramMap.get('id')));
      }
      if(this.route.snapshot.paramMap.get('id').match(/^[0-9a-fA-F]{24}$/)){
        this.route.queryParams
        .subscribe(params => {
          //console.log(params['id']);
           this.routeParameters= this.route.snapshot.paramMap.get('id') //params.userId;
            this.onClick();
        });
      // console.log(this.routeParameters,this.typeParams,"sdfjdnjnsdjgn")
        }
      }


       
  
   

 

 



        onClick(){
            this.isCurrentDayRecords=false;
            window.scrollTo(0, 0);
            this.spinner.show()
            const body = {
                'userId':this.routeParameters
              };
            const params = new HttpParams({
                fromObject: body
              });
            const url =  AllEndPoints.Booking.currentDayMaidsRecords;
            return this.http.get<any>(url+`?${params}`)
            .subscribe(
                (data: any)  => {
                    this.spinner.hide()
                    this.selectedDayRecord = data.data;
                    if(this.selectedDayRecord.length>0){
                      this.selectedWorkingDate = data.data[0].workingDate;
                      this.offset = moment.tz(data.data[0].timeZone).format('Z')
                    
                        this.isCurrentDayRecords=true;
                    }
                    
                    // console.log(data.data, this.selectedWorkingDate,"data in all view")
    
                },
                (error: { error: { message: string;statusCode:number }; })  => {
                    // this.commonService.authentication(error)
                    this.spinner.hide();
                    console.log(error)
                //       this.errorlog_book= true ;
                //   this.errormsg_book = error.error.message ;
                      return;
                  }
            )
        } 

}