import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaidPrivacyPolicyComponent } from './maid-privacy.component';
import { MaidPrivacyPolicyRoutingModule } from './maid-privacy.routing';



@NgModule({
  declarations: [MaidPrivacyPolicyComponent],
  imports: [
    CommonModule,
    MaidPrivacyPolicyRoutingModule
  ]
})
export class MaidPrivacyPolicyModule { }
