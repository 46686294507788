import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyfavouritesRoutingModule } from './my-favourites.routing';
import { MyFavouritesComponent } from './my-favourites';
import { MyFavouritesListingComponent } from './my-favourites-listing/my-favourites-listing.component';



@NgModule({
  declarations: [MyFavouritesComponent, MyFavouritesListingComponent],
  imports: [
    CommonModule,
    MyfavouritesRoutingModule
  ]
})
export class MyFavouritesModule { }
