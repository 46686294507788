import { Component, ViewChild, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navrbar.service';
import { OtpService } from './otp.service';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from '../login/login.service';
import { AccountService } from 'src/app/services/account/account.service';
import { SignupService } from "../signup";
import { MessageService } from "src/app/services/message.service";
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase/app";
declare var $: any;
declare var gtag

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.css', './otp.less'],
  encapsulation: ViewEncapsulation.None
})
  export class OtpComponent implements OnInit, OnDestroy {
    @Input() id: string;
    Form: FormGroup;
    submitted = false;
    errorlog = false;
    errormsg: string = '';
    private element: any;
    obj: {};

    object: any;
    isVisibleSignUpPopUp: boolean;
    isClickBackGround: boolean;
  successmsg: any;
  display: any;
  public timerInterval: any;
  successlog: boolean;
  show: boolean = false;
    statSec: number;
    seconds: number;
    verificationId: any;
    // public recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    // windowRef: any;
    isVerified: any;
    windowRef: any;
    window: any;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private navbarSvc: NavbarService,
        private modalService: OtpService,
        public el: ElementRef,        
        public messageService: MessageService,
        public commonService: CommonService,
        private spinner: NgxSpinnerService,
        private loginService: LoginService,
        private accountSvc: AccountService,
        public signupService:SignupService,
        private afAuth: AngularFireAuth

        // private modal: NgbModal
    ) {
        this.navbarSvc.showSimpleNavbar();
        this.navbarSvc.showFooter();
        this.element = el.nativeElement;
        
    }
    ngAfterViewInit() {
    }
    ngOnInit(): void {
        // alert('otp');
        this.isVisibleSignUpPopUp = false;
        this.initializeSignUpForm();
        this.window = this.modalService.windowRef;
        this.windowRef = this.modalService.windowRef;
        // this.windowRef = this.modalService.windowRef
        // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        // this.windowRef.recaptchaVerifier.render();

        console.error('otp init------------');
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        console.log("this.element",this.element);
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'login-ui' || this.isVisibleSignUpPopUp == true) {
                this.close();
            }
        })
       
        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);

        this.isVerified = localStorage.getItem('isVeriried');
        // if(this.isVerified) {
        //     this.sendOTP();
        // }
        
        // this.timer(1);
        
    }

     ngOnDestroy() {
        clearInterval(this.timerInterval);
      }

    //   sendOTP() {
    //     console.log("12213");
    //     const appVerifier = this.windowRef.recaptchaVerifier;
    //     const countryCode = JSON.parse(localStorage.getItem('userData')).countryCode;
    //     const phoneNo = JSON.parse(localStorage.getItem('userData')).phoneNo;
    //     // const phone = countryCode + phoneNo;
    //     const phone = "+919646500000";
    //     // this.accountSvc.sendFireBaseOTP(phone, appVerifier)
    //     //     .then((resp: any) => {
    //     //     // Store verificationId for later use
    //     //     console.log('Verification ID:', resp);
    //     //     localStorage.setItem("verificationId", JSON.stringify(resp))
    //     //     this.appVerifier.clear();
    //     //     })
    //     //     .catch((error) => console.error('Error sending OTP:', error));
    //     firebase.auth().signInWithPhoneNumber(phone, appVerifier).then(result => {
    //         this.windowRef.confirmationResult = result;
    //         console.log(result);
    //       })
    //       .catch( error => console.log(error) );
    // }


    initializeSignUpForm() {
        this.Form= this.formBuilder.group({
            'otp': new FormControl('', [Validators.required]),
        });
        
    }

    get f() { return this.Form.controls; }

    onSubmitForm() {
        this.verificationId = JSON.parse(localStorage.getItem("verificationId"));
        console.log(this.verificationId);

        this.spinner.show();
        this.isVisibleSignUpPopUp = false;
        this.submitted = true;
        if (this.Form.invalid) {
            this.spinner.hide();
            return;
        }
        this.obj = {
            "otp": this.Form.controls.otp.value,
                "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220"
            }
            console.log(this.Form.invalid);
            console.log(this.obj);
            
            this.accountSvc.verifyFireBaseOTP(this.verificationId.verificationId, this.Form.controls.otp.value).then((resp: any) => {
                console.log(resp);
                this.accountSvc.verifyOTP(this.obj).subscribe((response:any) => {
                    debugger;
                    console.log(response);
                    if(response.data.profileComplete == true) {
                        this.isVisibleSignUpPopUp = true;
                        document.body.classList.remove('login-ui-open');
                        this.spinner.hide();
                        this.Form.reset();
                        this.messageService.serviceType.next(response.data.fullName)
                        this.doLoginData(response);
                    }
                    else {
                        localStorage.removeItem("accessToken");
                        this.router.navigateByUrl('/register');
                    }
                    // console.log(response, "inside response")
                }, (error: { error: { message: string; statusCode: number }; }) => {
                    debugger;
                    this.spinner.hide();
                    this.errorlog = true;
                    setTimeout(()=>{
                        this.errorlog = false;
                    }, 3000)
                    this.errormsg = error.error.message;
                    return;
                })
                // return false;
                // this.isVisibleSignUpPopUp = true;
                // document.body.classList.remove('login-ui-open');
                // this.spinner.hide();
                // this.Form.reset();
                // this.doLoginData(resp);
            })
            .catch((error: any) => {
                debugger;
                console.log(error);
                this.errorlog = true;
                if(error.code == "auth/invalid-verification-code") {
                    debugger;
                    this.errormsg = 'Invalid OTP';
                }
                this.spinner.hide();
                // setTimeout(()=>{
                //     this.errorlog = false;
                // }, 3000)
                // this.errormsg = error.error.message;
                return;
            })

        
    }

    resendOTP() {
        this.show = true;
        this.timer(1);
        // this.spinner.show();
        console.log("12213");
        if(!this.window.recaptchaVerifier) {
            this.window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container-1', { 'size': 'invisible' });
        }
        const appVerifier = this.window.recaptchaVerifier;
        const countryCode = JSON.parse(localStorage.getItem('userData')).countryCode;
        const phoneNo = JSON.parse(localStorage.getItem('userData')).phoneNo;
        console.log(countryCode+phoneNo);
        const phone = countryCode + phoneNo;
        firebase.auth().signInWithPhoneNumber(phone, appVerifier).then(result => {
            this.spinner.hide();
            this.windowRef.confirmationResult = result;
            console.log(result);
            localStorage.setItem("verificationId", JSON.stringify(result));
          })
          .catch( error => {
              debugger;
              console.log(error);
            this.spinner.hide();
            this.timer(1);
            appVerifier.clear();
          } );
    }

    // resendOTP() {
    //     this.spinner.show();
    //     console.log("12213");   
    //     const countryCode = JSON.parse(localStorage.getItem('userData')).countryCode;
    //     const phoneNo = JSON.parse(localStorage.getItem('userData')).phoneNo;
    //     // const phone = countryCode + phoneNo;
    //     const phone = "+919646500000";
    //     this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
    //     this.accountSvc.sendFireBaseOTP(phone)
    //     .then((resp: any) => {
    //         // Store verificationId for later use
    //         console.log('Verification ID:', resp);
    //         localStorage.setItem("verificationId", JSON.stringify(resp))
    //         this.appVerifier.clear();
    //         this.spinner.hide();
    //         })
    //         .catch((error) => {
    //             this.spinner.hide();
    //             console.error('Error sending OTP:', error);
    //             return;
    //         })
    // }

    // resendOTP(){
    //   this.spinner.show();
    //       this.obj = {
    //           "uniquieAppKey": "892d9a5e3c21f60eb95ad196da1b0220"
    //       }
          
    //       this.accountSvc.resendOTP(this.obj).subscribe((response:any) => {
    //           this.spinner.hide();
    //           this.Form.reset();
    //           this.successlog = true;
    //           setTimeout(()=>{
    //             this.successlog = false;
    //         }, 3000)
    //           this.successmsg = response.data.message;
    //           this.timer(1);
    //           // console.log(response, "inside response")
    //       }, (error: { error: { message: string; statusCode: number }; }) => {
    //           this.spinner.hide();
    //           this.errorlog = true;
    //           setTimeout(()=>{
    //             this.errorlog = false;
    //         }, 3000)
    //           this.errormsg = error.error.message;
    //           return;
    //       })


    // }

    timer(minute) {
      // let minute = 1;
      console.log(minute);
      this.seconds = 30 // minute * 60;
      let textSec: any = '0';
      this.statSec = 30;
    //   this.show = true;
      const prefix = minute < 10 ? '0' : '';
  
      this.timerInterval = setInterval(() => {
        this.seconds--;
        if (this.statSec != 0) this.statSec--;
        else this.statSec = 29;
  
        if (this.statSec < 10) {
          textSec = '0' + this.statSec;
        } else textSec = this.statSec;
  
        this.display = `${prefix}${Math.floor(this.seconds / 60)}:${textSec}`;
//   console.log(this.display)
        if (this.seconds == 0) {
          console.log('finished');
          this.show = false;
          clearInterval(this.timerInterval);
        }
      }, 1000);
    }

    resetTimer(){
        this.statSec = 0;
        this.seconds = 0;
     }


    onCloseModal() {
        this.isClickBackGround=false;
        this.element.style.display = 'none';
    }

    doLoginData(response: any) {
        this.navbarSvc.loginVar = true;
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
        localStorage.setItem("loginType", '1');
        localStorage.setItem("isVerified", "true");
        localStorage.setItem("signUserData", 'true');
        localStorage.setItem("isVerified", response.data.isVerified);
        localStorage.setItem("emailVerified", response.data.emailVerified);
        gtag('event', 'form_submit_register_success', {
            'event_category': 'form_submit',
            'event_label': 'Signup Page',
            'value': 'form_submit_register_success'   })
        this.router.navigateByUrl('/hire-cleaner-in-London');
    }
   


    // open modal
    open(): void {
        // this.show = true;
        this.isClickBackGround=true;
        this.Form.reset();
        this.submitted = false;
        this.isVisibleSignUpPopUp = false;
        this.element.style.display = 'block';
        document.body.classList.add('login-ui-open');
        // this.timer(1);
    }

    // close modal
    close(): void {
        this.isClickBackGround=false;
        this.Form.reset();
        this.element.style.display = 'none';
        document.body.classList.remove('login-ui-open');
    }


  openOTPModal(id: string) {
      this.close();
      this.signupService.open(id);
  }

  closeOTPModal(id: string) {
      this.signupService.close(id);
  }


    openLoginModal(id: string) {
        this.close();
        this.loginService.open(id);
    }

    closeLoginModal(id: string) {
        this.loginService.close(id);
    }
    // login with gmail
   
}
