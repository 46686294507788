import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavbarService } from '../../services/navrbar.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../../account/login/login.service';
@Component({
  selector: 'app-safety-guards',
  templateUrl: './safety-guards.html',
  styleUrls: ['./safety-guards.css', '../../shared-css/static-page.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SafetyGuardsComponent implements OnInit {

  constructor(private navbarSvc: NavbarService,
    private router: Router,
    private modalService: LoginService,
    private spinner: NgxSpinnerService) {
    this.navbarSvc.showSimpleNavbar()
    this.navbarSvc.showFooter();    
  }

  ngOnInit() {
    
  }

  redirectToPage() {
       
    if(this.navbarSvc.loginVar == false){
        this.modalService.open('custom-modal-12');
    }else{
        this.spinner.show();
        this.router.navigateByUrl('/hire-cleaner-in-London');
   }
   localStorage.removeItem('bookingTabType');
   localStorage.removeItem("maidDetails");
   localStorage.removeItem("isReschedule")
   localStorage.removeItem("maidId")
    localStorage.removeItem("amount")
   localStorage.removeItem('serviceId')
   localStorage.removeItem('referenceId');
   localStorage.removeItem('serviceId');
   localStorage.removeItem('isBookAgain');
   localStorage.removeItem('maidDetails');
   localStorage.removeItem('isMyfavouriteMaid');
   localStorage.removeItem('isCancelPayemnt');
   localStorage.removeItem("maidId")
   localStorage.removeItem("isBack");
   localStorage.removeItem("bookingTabType")

}

}
