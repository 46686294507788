import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { AllEndPoints } from '../shared-models/end-points';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    public noHeaders: HttpHeaders;
    public Headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.noHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Header': 'True' });
        this.Headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.Headers = new HttpHeaders();
        
        if(localStorage.getItem('accessToken')){
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
        this.Headers = new HttpHeaders({ 'authorization': 'bearer ' + localStorage.getItem('accessToken') });
    }
}

 


    updateProfile(data) {
        // console.log(data)
        const url = AllEndPoints.Settings.updateProfile;
        return this.http.put(url, data, { headers: this.Headers })
            .map(res => res)
    }

    changePassword(data) {
        const url = AllEndPoints.Settings.changePassword;
        return this.http.put(url, data, { headers: this.Headers })
            .map(res => res)
    }

    deleteAccount(data) {
        const url = AllEndPoints.Settings.deleteAccount;
        return this.http.put(url, data, { headers: this.Headers })
            .map(res => res)
    }

}